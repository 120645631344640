import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

interface TalkHasFinishedProps {
  nextTalkIdentifier?: string;
}

export const TalkHasFinished = ({ nextTalkIdentifier }: TalkHasFinishedProps) => (
  <div
    className="video-overlay"
  >
    {nextTalkIdentifier ? (
      <Link
        to={`/app/talk/${nextTalkIdentifier}`}
        className="talk-status talk-status-finished"
      >
        <Button
          style={{
            padding: "auto 20px",
            fontSize: "15px",
            height: "36px",
          }}
          type="primary"
          danger
        >
          Go to next talk
        </Button>
      </Link>
    ) : (
      <span className="talk-status talk-status-finished">
        Talk has finished
      </span>
    )}
  </div>
);
