import {
  getDefaultMiddleware,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { AuthStore } from "stores/AuthStore";
import storage from "redux-persist/lib/storage";
import { userStore, connectionStore } from "app/infra/user";
import { kvStore } from "app/common/store";
import { postStore } from "app/infra/posts";
import { companyStore } from "app/infra/company";
import { highlightStore } from "app/infra/highlights";
import { raffleStore } from "app/infra/raffle";
import { sponsorshipStore } from "app/infra/sponsorships";
import { stageStore } from "app/infra/stage";
import { PushNotificationStore } from "app/infra/pushNotification/pushNotification.store";
import { chatStore } from "app/infra/chat";
import { videoStreamStore } from "app/infra/videoStream";
import { perkStore } from "../app/infra/perk";
import { talkStore } from "../app/infra/talk";
import { trackStore } from "../app/infra/track";
import { scheduleStore } from "../app/infra/schedule";

const rootReducer = combineReducers({
  authStore: AuthStore.reducer,
  postStore: postStore.reducer,
  userStore: userStore.reducer,
  connectionStore: connectionStore.reducer,
  companyStore: companyStore.reducer,
  kvStore: kvStore.reducer,
  highlightStore: highlightStore.reducer,
  raffleStore: raffleStore.reducer,
  sponsorshipStore: sponsorshipStore.reducer,
  perkStore: perkStore.reducer,
  stageStore: stageStore.reducer,
  talkStore: talkStore.reducer,
  trackStore: trackStore.reducer,
  scheduleStore: scheduleStore.reducer,
  pushNotificationStore: PushNotificationStore.reducer,
  chatStore: chatStore.reducer,
  videoStreamStore: videoStreamStore.reducer,
});

// @ts-ignore
window._AWTrackProxy = window._AWTrack || [];
// @ts-ignore
const tracker = (store) => (next) => (action) => {
  // @ts-ignore
  window._AWTrackProxy = window._AWTrack || window._AWTrackProxy;
  const trackObject = {
    type: `reducer.${action.type}`,
    payload: action.payload,
    context: store.getState(),
  };
  // @ts-ignore
  window._AWTrackProxy.push(trackObject);
  return next(action);
};

const persistConfig = {
  key: "app",
  whitelist: ["authStore", "kvStore", "chatStore", "videoStreamStore"],
  storage,
  middleware: [...getDefaultMiddleware()],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // @ts-ignore
  middleware: [tracker],
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store, {});
