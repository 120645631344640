import React from "react";
import { Link } from "react-router-dom";

import { useAsync, useOnError } from "hooks";
import { AuthService } from "services/AuthService";

import { Button, Form, Input } from "antd";
import { Lock, User } from "react-feather";

import { Logo } from "components/Pro/Logo";

export const SessionForm = () => {
  const { execute, isPending, error } = useAsync(AuthService().sessions.create);
  useOnError(error);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container pro-card">
        <Form
          layout="vertical"
          onFinish={execute}
          className="auth-form"
        >
          <Logo />

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Enter email"
              prefix={<User size={20} />}
              className="pro-input"
            />
          </Form.Item>

          <Form.Item
            className="password-form-item"
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password
              placeholder="Enter password"
              prefix={<Lock size={20} />}
              className="pro-input"
            />
          </Form.Item>

          <div className="forgot-password">
            <Link to="/auth/reset-password">Forgot password?</Link>
          </div>

          <Form.Item>
            <Button
              type="primary"
              block={true}
              htmlType="submit"
              loading={isPending}
              style={{
                borderRadius: "23px",
              }}
            >
              LOGIN
            </Button>
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Link to="/auth/login">
              <Button
                type="default"
                block={true}
                style={{
                  borderRadius: "23px",
                }}
              >
                Login via email
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
