import React, { useState } from "react";

import { Button } from "antd";
import { Play } from "react-feather";

interface ForcePlayProps {
  play: () => void;
  setShowButton: (val: boolean) => void;
}

export const ForcePlay = ({ play, setShowButton }: ForcePlayProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      className="stream-not-playing"
      onClick={() => {
        setLoading(true);
        play();

        setTimeout(() => {
          setLoading(false);
          setShowButton(false);
        }, 1000);
      }}
    >
      <span>
        <Play size={18} style={{ verticalAlign: "middle" }} />
      </span>

      <Button
        type="primary"
        danger={true}
        loading={loading}
        style={{
          fontSize: "15px",
          fontWeight: 600,
          borderWidth: 1,
          borderRadius: 5,
          height: 35,
          padding: "3px 15px",
        }}
      >
        Stream not playing? Click here.
      </Button>
    </div>
  );
};
