/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect } from "react";
import { strapiApi } from "services";
import { useHistory, useParams } from "react-router-dom";

const TalksRedirect = () => {
  const history = useHistory();
  const params: any = useParams();
  const trackId = params.id;

  useEffect(() => {
    const fetchPath = async () => {
      const res = await strapiApi.get(`/adw-pro-tracks-links?link=/track/${trackId}&_limit=1`);
      if (res.data.length) {
        const { path } = res.data[0];
        history.replace(`/app${path}`);
      }
    };

    try {
      fetchPath().catch(console.error);
    } catch (error) {
      console.log(error);
    }
  }, [trackId]);

  return null;
};

export default TalksRedirect;
