import { createSlice } from "@reduxjs/toolkit";

interface AddKVAction {
  payload: {
    identifier: string;
    value: any;
  };
}

interface ById {
  [key: string]: any;
}

export const kvStore = createSlice({
  name: "kvStore",
  initialState: {
    kv: {} as ById,
  },
  reducers: {
    addKV: (state, action: AddKVAction) => {
      state.kv[action.payload.identifier] = action.payload.value;
    },
  },
});
