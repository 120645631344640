/* eslint-disable max-len */
import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import _ from "lodash";

import { DiContext } from "app/common";
import { useAsync, useModal, useOnError } from "hooks";

import { UserEntity, userService } from "app/infra/user";

import { country_list } from "app/common/data";
import { attendeeInterests } from "app/core/user";

import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
  PageHeader,
  Row,
  Select,
  Tabs,
  Upload,
} from "antd";
import type { RcFile } from "antd/lib/upload";
import type { SelectValue } from "antd/lib/select";
import { AlertTriangle, ChevronDown, ChevronUp, CreditCard, Lock, LogOut, RefreshCw, Settings } from "react-feather";

import ImgCrop from "antd-img-crop";

import { Loading } from "components/Loading/Loading";
import { UserAvatar } from "./UserAvatar";
import { UserPasswordForm } from "./UserPasswordForm";

const { TabPane } = Tabs;

interface UserFormProps {
  user: UserEntity;
}

export const UserUpdateForm = (props: UserFormProps) => {
  const jobPositions = [
    "Account Manager",
    "Account Strategist",
    "CEO",
    "CMO",
    "Content Marketer",
    "Copywriter",
    "Creative Strategist",
    "CRO Expert",
    "Designer",
    "Digital Marketer",
    "Director",
    "Ecommerce Manager",
    "Email Marketer",
    "Founder",
    "Growth Manager",
    "Marketing Manager",
    "Media Buyer",
    "Social Media Manager",
    "Other",
  ];

  const merchandiseList = [
    "Baby Products",
    "Clothing",
    "Electronics",
    "Food & Drink",
    "Furniture",
    "Handcrafts",
    "Health & Beauty",
    "Jewellery",
    "Painting",
    "Photography",
    "Services",
    "Sports",
    "Toys",
    "Virtual Services",
    "Other",
  ];

  const [form] = Form.useForm();

  const beforeUpload = (file: RcFile) => {
    if (file.size > 5000000) {
      notification.error({ message: "Photo must be less than 5mb" });
      return false;
    }

    return true;
  };

  const { apiService, dispatch } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch });

  const {
    execute: upload,
    isPending: isUploadPending,
    error: uploadError,
  } = useAsync(({ file }: { file: string | Blob }) => {
    return userSrv.updateProfilePhoto({ photo: file }).then((response) => {
      return response;
    });
  });

  useOnError(uploadError);

  const {
    execute: deleteAvatar,
    isPending: isDeleteAvatarPending,
    error: deleteAvatarError,
  } = useAsync(() => {
    return userSrv.deleteProfilePhoto().then((response) => {
      return response;
    });
  });

  useOnError(deleteAvatarError);

  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const { execute, isPending, error } = useAsync((data: any) => {
    setIsError(false);

    return userSrv.updateMe(data).then((response) => {
      notification.success({ message: "Profile was successfully updated" });
      return response;
    });
  });

  useOnError(error);

  const chargebeePortalCreation = useAsync(userSrv.chargebee.portals.create);
  useOnError(chargebeePortalCreation.error);

  const handleError = (errorInfo: any) => {
    setIsError(true);

    notification.error({
      message: "Invalid form",
      description: "Please fix all invalid fields to be able to save your changes",
    });
  };

  const handleTabClick = (key: string) => {
    if (key === "2") {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      history.push(`/app/company-profile/${props.user.company!.id}/edit`);
    }
  };

  const passwordModal = useModal();
  const [passwordForm] = Form.useForm();

  const [isJobPositionOther, setIsJobPositionOther] = useState(
    props.user.job_position && (
      props.user.job_position.toLowerCase() === "other"
      || jobPositions.findIndex((el) => el === props.user.job_position) === -1
    ),
  );

  const handleOptionalSelect = (value: SelectValue) => {
    if (value.toString().toLowerCase() === "other") {
      form.setFieldsValue({ job_position: "" });
      setIsJobPositionOther(true);
      setTimeout(() => { form.getFieldInstance("job_position").input.focus(); }, 250);
    } else {
      form.setFieldsValue({ job_position: value.toString() });
      setIsJobPositionOther(false);
    }
  };

  const [isMerchandiseOther, setIsMerchandiseOther] = useState(
    props.user.merchandise && (
      props.user.merchandise?.toLowerCase() === "other"
      || merchandiseList.findIndex((el) => el === props.user.merchandise) === -1
    ),
  );

  const handleMerchandiseOptionalSelect = (value: SelectValue) => {
    if (value.toString().toLowerCase() === "other") {
      form.setFieldsValue({ merchandise: "" });
      setIsMerchandiseOther(true);
      setTimeout(() => { form.getFieldInstance("merchandise").input.focus(); }, 250);
    } else {
      form.setFieldsValue({ merchandise: value.toString() });
      setIsMerchandiseOther(false);
    }
  };

  const passwordActionText = useMemo(() => {
    return props.user.hasPassword ? "Change Password" : "Setup Password";
  }, [props.user.hasPassword]);

  const handleRedirectToChargebeePortal = () => {
    const link = "https://adworld.chargebeeportal.com/portal/v2/login?forward=portal_main";
    window.open(link, "_blank");
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={execute}
      onFinishFailed={handleError}
    >
      <PageHeader
        ghost={false}
        className="user-profile"
        title={(
          <div style={{ display: "flex" }}>
            <UserAvatar user={props.user} style={{ marginRight: "15px" }} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <span>
                <span style={{ fontSize: "35px", fontWeight: "bold" }}>
                  {props.user.first_name} {props.user.last_name}
                </span>
                <span style={{ fontSize: "27px", fontWeight: "normal" }}>{" "}/ Edit profile</span>
              </span>
              <span style={{ fontSize: "12px", lineHeight: "12px" }}>Build your profile to make the most of Ad World Pro</span>
            </div>
          </div>
        )}
        extra={[
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <Button
              key="update-user-form-reset-info-btn"
              type="link"
              icon={(
                <RefreshCw size={20} style={{ verticalAlign: "middle", cursor: "pointer", marginRight: 10 }} />
              )}
              onClick={() => { form.resetFields(); }}
            >
              Reset info
            </Button>,

            <Button key="update-user-form-save-changes-btn" className="pro-ant-btn" style={{ padding: "25px 50px" }} type="primary" htmlType="submit">
              Save profile
            </Button>
          </div>,
        ]}
        style={{
          position: "sticky",
          top: "77px",
          width: "100%",
          zIndex: 999,
          borderBottom: "1px solid rgba(36, 38, 45, 0.3)",
          backgroundColor: "#f4f5f8",
        }}
      />

      <Layout style={{ marginTop: "40px" }}>
        {/* <Tabs
          defaultActiveKey="1"
          tabBarGutter={40}
          tabBarStyle={{ borderBottom: 0 }}
          onTabClick={handleTabClick}
        >
          <TabPane
            key="1"
            tab="My Profile"
          />

          {props.user.company && (
            <TabPane
              key="2"
              tab="Company Profile"
            />
          )}
        </Tabs> */}

        <Row gutter={0}>
          <Col xs={24} md={6}>
            <Menu
              className="pro-menu"
              style={{
                backgroundColor: "transparent",
                color: "#181922",
                fontSize: "14px",
              }}
              defaultSelectedKeys={["1"]}
            >
              <Menu.Item key="1">
                <Settings size={20} />
                Edit profile
              </Menu.Item>

              <Menu.Item onClick={passwordModal.open}>
                <Lock size={20} />
                Password
              </Menu.Item>

              <Menu.Item
                // onClick={chargebeePortalCreation.execute}
                onClick={handleRedirectToChargebeePortal}
              >
                {chargebeePortalCreation.isPending ? (
                  <Loading size={20} style={{ marginRight: "10px", verticalAlign: "middle" }} />
                ) : (
                  <CreditCard size={20} />
                )}
                Billing
              </Menu.Item>

              <Menu.Item>
                <LogOut size={20} />
                Sign out
              </Menu.Item>
            </Menu>
          </Col>

          <Col xs={24} md={18}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "25px",
              }}
            >
              <ImgCrop>
                <Upload
                  name="avatar"
                  accept=".png, .jpg, .jpeg"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={upload}
                  className="ant-upload-picture-card-wrapper-circle"
                  disabled={isPending || isUploadPending}
                >
                  <span>
                    <UserAvatar user={props.user} size={108} style={{ zIndex: 0 }} />

                    {isUploadPending && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          background: "#FFFFFF",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          zIndex: 4,
                        }}
                      >
                        <Loading
                          style={{ marginTop: "5px" }}
                          size={30}
                        />
                      </div>
                    )}
                  </span>
                </Upload>
              </ImgCrop>

              <div>
                <ImgCrop>
                  <Upload
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    customRequest={upload}
                    disabled={isPending || isUploadPending}
                  >
                    <Button
                      type="primary"
                      style={{ margin: "0px 20px" }}
                      className="pro-ant-btn"
                    >
                      Upload new picture
                    </Button>
                  </Upload>
                </ImgCrop>

                {props.user.profile_picture_url && (
                  <Button
                    type="ghost"
                    style={{
                      background: "rgba(36, 38, 45, 0.1)",
                      color: "#05003899",
                    }}
                    className="pro-ant-btn"
                    onClick={() => { deleteAvatar(); }}
                    loading={isDeleteAvatarPending}
                    disabled={isDeleteAvatarPending}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>

            <Collapse
              ghost={true}
              accordion={false}
              defaultActiveKey={[1, 2]}
              className="pro-ant-collapse"
              expandIcon={(panelProps) => (
                <span
                  role="img"
                  aria-label="right"
                  className="ant-collapse-arrow"
                >
                  {panelProps.isActive ? <ChevronUp size={20} color="#F75E2E" /> : <ChevronDown size={20} />}
                </span>
              )}
            >
              <Collapse.Panel
                key={1}
                header={(
                  <h1 style={{ verticalAlign: "middle" }}>
                    <span role="presentation" aria-hidden="true">👋</span> About you
                  </h1>
                )}
              >
                <Row gutter={20}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="first_name"
                      label="First Name"
                      initialValue={props.user.first_name}
                      rules={[
                        {
                          required: true,
                          message: "First Name is required",
                        },
                        {
                          min: 2,
                          max: 20,
                          message: "First Name must be within 2 - 20 characters",
                        },
                      ]}
                    >
                      <Input placeholder="First Name" disabled={isPending} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      initialValue={props.user.last_name}
                      rules={[
                        {
                          required: true,
                          message: "Last Name is required",
                        },
                        {
                          min: 2,
                          max: 20,
                          message: "Last Name must be within 2 - 20 characters",
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" disabled={isPending} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="gender"
                      label="Gender"
                      initialValue={props.user.gender}
                    >
                      <Select placeholder="Gender" disabled={isPending}>
                        <Select.Option value="female">Female</Select.Option>
                        <Select.Option value="male">Male</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="age"
                      label="Age"
                      initialValue={props.user.age}
                    >
                      <Select placeholder="Age" disabled={isPending}>
                        <Select.Option value="18-24">18-24</Select.Option>
                        <Select.Option value="25-34">25-34</Select.Option>
                        <Select.Option value="35-44">35-44</Select.Option>
                        <Select.Option value="45-54">45-54</Select.Option>
                        <Select.Option value="55-64">55-64</Select.Option>
                        <Select.Option value="65+">65+</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label={(
                    <>
                      <span role="presentation" aria-hidden="true">👔</span>&nbsp; Job Title
                    </>
                  )}
                  required={true}
                >
                  <Select
                    placeholder="Job Title e.g. CEO"
                    showSearch={true}
                    disabled={isPending}
                    onChange={handleOptionalSelect}
                    defaultValue={isJobPositionOther ? "Other" : String(_.toString(props.user.job_position)) || undefined}
                  >
                    {jobPositions.map((position) => (
                      <Select.Option key={`job-title-${position}`} value={position}>{position}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="job_position"
                  initialValue={props.user.job_position}
                  shouldUpdate={(prevValues, currentValues) => prevValues.job_position !== currentValues.job_position}
                  rules={[
                    {
                      required: true,
                      message: "Job Title is required",
                    },
                    {
                      min: 2,
                      max: 30,
                      message: "Job Title must be withing 2 - 30 characters",
                    },
                  ]}
                  style={{ display: isJobPositionOther ? "block" : "none" }}
                >
                  <Input
                    placeholder="Job Title"
                    disabled={isPending}
                  />
                </Form.Item>

                <Row gutter={20}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="attendee_company"
                      label={(
                        <>
                          <span role="presentation" aria-hidden="true">💼</span>&nbsp; Company Name
                        </>
                      )}
                      initialValue={props.user.attendee_company}
                      rules={[
                        {
                          min: 2,
                          max: 50,
                          message: "Company Name must be within 2 - 50 characters",
                        },
                      ]}
                    >
                      <Input placeholder="Company Name" disabled={isPending} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="attendee_company_size"
                      label={(
                        <>
                          <span role="presentation" aria-hidden="true">👥</span>&nbsp; What is your Company size?
                        </>
                      )}
                      initialValue={props.user.attendee_company_size}
                    >
                      <Select placeholder="Company Size" disabled={isPending}>
                        <Select.Option value="less-5">Small &lt;5 Employees</Select.Option>
                        <Select.Option value="10-75">Small to Medium 10 - 75 Employees</Select.Option>
                        <Select.Option value="75-500">Medium 75 - 500 Employees</Select.Option>
                        <Select.Option value="500-5000">Medium to Large 500 - 5000 Employees</Select.Option>
                        <Select.Option value="5000+">Large 5000&gt; Employees</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label={(
                    <>
                      <span role="presentation" aria-hidden="true">💻</span>&nbsp; What do you sell?
                    </>
                  )}
                  required={true}
                >
                  <Select
                    placeholder="What do you sell?"
                    showSearch={true}
                    disabled={isPending}
                    onChange={handleMerchandiseOptionalSelect}
                    defaultValue={isMerchandiseOther ? "Other" : props.user.merchandise}
                  >
                    {merchandiseList.map((merchandise) => (
                      <Select.Option key={`job-title-${merchandise}`} value={merchandise}>{merchandise}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="merchandise"
                  initialValue={props.user.merchandise}
                  shouldUpdate={(prevValues, currentValues) => prevValues.merchandise !== currentValues.merchandise}
                  rules={[
                    {
                      required: true,
                      message: "Field value is required",
                    },
                    {
                      min: 2,
                      max: 30,
                      message: "Field value must be withing 2 - 30 characters",
                    },
                  ]}
                  style={{ display: isMerchandiseOther ? "block" : "none" }}
                >
                  <Input
                    placeholder="What do you sell?"
                    disabled={isPending}
                  />
                </Form.Item>

                <Form.Item
                  name="interested"
                  label={(
                    <span>
                      <span role="presentation" aria-hidden="true">📚</span>&nbsp;
                      Topics you're interested in <small>(Select up to 3)</small>
                    </span>
                  )}
                  rules={[
                    {
                      required: true,
                      type: "array",
                      min: 2,
                      message: "You must select at least 2 topics of interest",
                    },
                    {
                      type: "array",
                      max: 3,
                      message: "You cannot select more than 3 topics of interest",
                    },
                  ]}
                  initialValue={props.user.interested}
                >
                  <Select
                    mode="multiple"
                    options={attendeeInterests.sort().map((item) => ({ value: item }))}
                    showArrow={true}
                    showSearch={true}
                    disabled={isPending}
                    placeholder="Topics you're interested in"
                  />
                </Form.Item>

                <Row gutter={20}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="country"
                      label={(
                        <>
                          <span role="presentation" aria-hidden="true">🌍</span>&nbsp; Country
                        </>
                      )}
                      rules={[
                        {
                          required: true,
                          message: "Country is required",
                        },
                      ]}
                      initialValue={props.user.country}
                    >
                      <Select placeholder="Country" showSearch={true} disabled={isPending}>
                        {country_list.map((country) => (
                          <Select.Option key={`user-update-form-country${country}`} value={country}>
                            {country}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="city"
                      label={(
                        <>
                          <span role="presentation" aria-hidden="true">🏙</span>&nbsp; City
                        </>
                      )}
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                        {
                          min: 2,
                          max: 50,
                          message: "City name must be within 2 - 50 characters",
                        },
                      ]}
                      initialValue={props.user.city}
                    >
                      <Input placeholder="City" disabled={isPending} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="questionWorkingOn"
                  label={(
                    <>
                      <span role="presentation" aria-hidden="true">🖥</span>&nbsp; What I'm Currently Working On:
                    </>
                  )}
                  rules={[
                    {
                      min: 2,
                      max: 50,
                      message: "Answer must be within 2 - 50 characters",
                    },
                  ]}
                  initialValue={props.user.questionWorkingOn}
                >
                  <Input placeholder="e.g. Lead generation for an upcoming sale" disabled={isPending} />
                </Form.Item>

                <Form.Item
                  name="questionBiggestChallenge"
                  label={(
                    <>
                      <AlertTriangle size={16} color="#181922" fill="#ffd426" />&nbsp; Biggest Challenge Right Now:
                    </>
                  )}
                  rules={[
                    {
                      min: 2,
                      max: 50,
                      message: "Answer must be within 2 - 50 characters",
                    },
                  ]}
                  initialValue={props.user.questionBiggestChallenge}
                >
                  <Input placeholder="e.g. iOS14 not attributing leads to our campaigns" disabled={isPending} />
                </Form.Item>
              </Collapse.Panel>

              <Collapse.Panel
                key={2}
                header={(
                  <h1 style={{ verticalAlign: "middle" }}>
                    <span role="presentation" aria-hidden="true">🔗</span> Your Social Links
                  </h1>
                )}
              >
                <Form.Item
                  name="website"
                  label="Website"
                  rules={[
                    {
                      type: "url",
                      message: "Not a valid URL",
                    },
                  ]}
                  initialValue={props.user.website}
                >
                  <Input placeholder="Website URL e.g. https://example.com" disabled={isPending} />
                </Form.Item>

                <Form.Item
                  name="linkedin"
                  label="LinkedIn"
                  rules={[
                    {
                      type: "url",
                      message: "Not a valid URL",
                    },
                    {
                      validator: (_, value: string) => {
                        if (!value || /^https:\/\/(www.|[a-z]{2}[.])?linkedin.com\/(in\/[\w\d&=-]*|profile\/view\?id=[\w\d&=-]*|pub\/[\w\d&=-]*|[\w\d&=/-]*)/.test(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject();
                      },
                      message: "Not a valid LinkedIn URL",
                    },
                  ]}
                  validateFirst={true}
                  initialValue={props.user.linkedin}
                >
                  <Input placeholder="Linkedin URL" disabled={isPending} />
                </Form.Item>

                <Form.Item
                  name="twitter"
                  label="Twitter"
                  rules={[
                    {
                      type: "url",
                      message: "Not a valid URL",
                    },
                    {
                      validator: (_, value: string) => {
                        if (!value || /^https:\/\/(www.|mobile.|m.)?twitter.com\/(@?[\w]{1,15})\/?/.test(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject();
                      },
                      message: "Not a valid Twitter URL",
                    },
                  ]}
                  validateFirst={true}
                  initialValue={props.user.twitter}
                >
                  <Input placeholder="Twitter URL" disabled={isPending} />
                </Form.Item>

                <Form.Item
                  name="facebook"
                  label="Facebook"
                  rules={[
                    {
                      type: "url",
                      message: "Not a valid URL",
                    },
                    {
                      validator: (_, value: string) => {
                        if (!value || /^https:\/\/(www.|mobile.|m.|touch.|mbasic.)?(facebook.com|fb.me)\/([\w#!?=/.%-]*)/.test(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject();
                      },
                      message: "Not a valid Facebook URL",
                    },
                  ]}
                  validateFirst={true}
                  initialValue={props.user.facebook}
                >
                  <Input placeholder="Facebook URL" disabled={isPending} />
                </Form.Item>

                <Form.Item
                  name="otherLink"
                  label="Other"
                  rules={[
                    {
                      type: "url",
                      message: "Not a valid URL",
                    },
                  ]}
                  validateFirst={true}
                  initialValue={props.user.otherLink}
                >
                  <Input placeholder="Other" disabled={isPending} />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>

            <Modal
              visible={passwordModal.isVisible}
              onOk={passwordForm.submit}
              okText={passwordActionText}
              onCancel={() => {
                passwordModal.shut();
                passwordForm.resetFields();
              }}
            >
              <UserPasswordForm form={passwordForm} onFinish={passwordModal.shut} user={props.user} />
            </Modal>
          </Col>
        </Row>
      </Layout>
    </Form>
  );
};
