import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { app } from "config";

import { connect } from "react-redux";
import { RootState } from "services";

import bannerHeaderSVG from "assets/images/adw/hero-top-banner.svg";
import logo from "assets/images/adw/logo/logo.svg";
import { Button } from "antd";
import { UserEntity } from "app/infra/user";
import { ReplaysCountdown } from "./ReplayCountdown";

interface HeroMap {
  [key: string]: string;
}

interface HeroProps {
  user: UserEntity | null;
}

const replaysWithTicket = (
  <div className="replays-hero-ticket">
    <div className="replays-hero-ticket__big">
      <h1 className="title">WELCOME TO THE</h1>
      <img className="logo" src={logo} alt={app.name} />
      <h1 className="title">REPLAY LIBRARY</h1>
    </div>
    <h1 className="replays-hero-ticket__title-small">WELCOME TO THE REPLAY LIBRARY</h1>
  </div>
);

const replaysWithoutTicket = (
  <div className="replays-hero-no-ticket">
    <div className="replays-hero-no-ticket__content">
      <div className="replays-hero-no-ticket__content__inner">
        <div className="replays-hero-no-ticket__content__inner__text">
          <h1>UPGRADE to get 1-year replay access</h1>
          <span>Access 300+ Ad World speeches on demand</span>
        </div>
        <a href={app.replayPassTitoURL} target="_blank" rel="noreferrer">
          <Button className="purchase-now-btn">PURCHASE NOW</Button>
        </a>
      </div>
    </div>
    <div className="replays-hero-no-ticket__countdown-box">
      <span className="countdown-title">FREE REPLAY ACCESS ENDS IN</span>
      <ReplaysCountdown time="2022-05-25T00:00:00.000Z" />
    </div>
  </div>
);

const mapStateToProps = (state: RootState): HeroProps => ({
  user: state.userStore.byId["me"],
});

export const Hero = connect(mapStateToProps)(
  (props: HeroProps) => {
    const location = useLocation();

    const titles: HeroMap = {
      "/app/updates": `what's new on ${app.name}`,
      "/app/stage": "stages",
      // "/app/replays": "replays",
      "/app/schedule": "schedule",
      "/app/directory/speakers": "networking",
      "/app/directory/attendees": "networking",
      "/app/directory/companies": "networking",
      "/app/perks": "perks",
      "/app/booths": "marketplace",
      "/app/user-profile": "directory",
    };

    const title = useMemo(() => {
      return titles[location.pathname.replace(/\/+$|[?|#].*$/, "")];
    }, [location.pathname]);

    if (!title) return null;

    return (
      <div className="page-hero-banner-wrapper">
        <div className="page-hero-banner" style={{ backgroundImage: `url(${bannerHeaderSVG})` }}>

          {location.pathname === "/app/replays" ? (
            <div className="replays-hero">
              { props.user?.replayAccess ? replaysWithTicket : replaysWithoutTicket }
            </div>
          ) : (
            <div>
              {location.pathname === "/app/schedule" ? (
                <div className="schedule-hero">
                  <h1>{title}</h1>
                  <div className="schedule-banner-info">
                    <div className="schedule-banner-info-first">
                      <h5>
                        <span role="presentation" aria-hidden="true" style={{ marginRight: "8px" }}>
                          🎉
                        </span>
                        3-WEEKS ALL ACCESS REPLAYS!
                      </h5>
                    </div>

                    <div className="schedule-banner-info-second">
                      <h5>Click <b>"Watch Talk"</b> on a past speech</h5>
                    </div>
                  </div>
                </div>
              ) : (
                <h1>{title}</h1>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);
