import React, { createContext, ReactChild, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import moment from "moment-timezone";
import { isBefore } from "date-fns";
import { isMobile } from "react-device-detect";

import { EPubSubTable, Tracking } from "app/common";
import { useIntercomLauncher } from "hooks/useIntercomLauncher";

import { TalkEntity } from "app/infra/talk";
import { UserEntity } from "app/infra/user";

import { Layout as AntLayout } from "antd";

import { NetworkStatusBar } from "components/NetworkStatusBar/NetworkStatusBar";
import { Header } from "components/Header/Header";
import { Footer } from "components/Footer/Footer";
import * as Banner from "./Banner";

declare global {
  interface Window {
    user: Record<string, unknown>;
  }
}

interface LayoutProps {
  children: ReactChild;
  isLoggedIn: boolean;
  openingTalk: TalkEntity;
  currentUser?: UserEntity;
}

const mapStateToProps = (state: RootState) => {
  const talks = Object.values(state.talkStore.byId);
  const openingTalk = talks.sort((a, b) => Date.parse(a.start_time) - Date.parse(b.start_time))[0];

  return {
    isLoggedIn: state.authStore.isLoggedIn,
    currentUser: state.userStore.byId["me"],
    openingTalk,
  };
};

interface AppContextProps {
  confOpenTime: string;
}

export const AppContext = createContext({ confOpenTime: "" } as AppContextProps);

export const Layout = connect(mapStateToProps)((props: LayoutProps) => {
  const [confOpenTime, setConfOpenTime] = useState("");

  useIntercomLauncher();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    return history.listen(() => {
      if (Tracking.User) {
        Tracking.PubSub([{
          table: EPubSubTable.PageView,
          data: {
            user_id: Tracking.User.id,
            datetime: Tracking.Date,
            path: `${window.location.pathname}${window.location.search}`,
          },
        }]);
      }
    });
  }, [history]);

  useEffect(() => {
    if (!Tracking.User && props.currentUser) {
      const oldId = Number(props.currentUser.old_id);
      const userId = Number(props.currentUser.id);
      let id;
      if (!Number.isNaN(oldId)) id = oldId;
      else if (!Number.isNaN(userId)) id = userId;
      else id = 0;

      if (id) Tracking.SetUser({ ...props.currentUser, id });
    }
  }, [props.currentUser]);

  const showCountdownBanner = useMemo(() => {
    return !location.pathname.startsWith("/app/chats") && !location.pathname.startsWith("/app/talk");
  }, [location]);

  const openingTime = useMemo(() => {
    return moment(props.openingTalk?.start_time).format("H A MMM Do");
  }, [props.openingTalk]);

  useEffect(() => {
    setConfOpenTime(props.openingTalk?.start_time);
  }, [openingTime]);

  const isBeforeOpening = useMemo(() => {
    return isBefore(Date.now(), Date.parse(props.openingTalk?.start_time));
  }, [props.openingTalk]);

  return (
    <AppContext.Provider value={{ confOpenTime }}>
      <AntLayout>
        {props.isLoggedIn && (
          <>
            <Header />
            <NetworkStatusBar />
          </>
        )}

        <AntLayout.Content>
          {props.isLoggedIn && !isMobile && (
            <Banner.Hero />
          )}

          {props.children}
        </AntLayout.Content>

        <Footer />
      </AntLayout>
    </AppContext.Provider>
  );
});
