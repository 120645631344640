import React, { useMemo, useContext, useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { app } from "config";

import { connect } from "react-redux";
import { RootState } from "services";

import moment from "moment-timezone";
import _, { uniqBy } from "lodash";

import { DiContext } from "app/common";
import { useAsync, useDebounce, useOnError, useOnMount, useSessionState } from "hooks";

import { logout } from "services/AuthService";

import { PushNotificationEntity, pushNotificationService, NotificationBareData } from "app/infra/pushNotification";
import { TalkEntity, talkService } from "app/infra/talk";
import { TrackEntity } from "app/infra/track";
import { UserEntity, userService } from "app/infra/user";

import { Badge, Col, Dropdown, Input, Layout, Menu, Row, Tag } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";

import {
  Bell,
  Bookmark,
  BookOpen,
  HelpCircle,
  Home,
  List,
  LogOut,
  MessageCircle,
  PieChart,
  Search,
  Settings,
  Shield,
  Users,
  Menu as Hamburger,
} from "react-feather";
import { parse } from "qs";
import logo from "assets/images/adw/logo/logo.svg";

import { UserAvatar } from "components/User/UserAvatar";
import CheckboxFilter, { StringBooleanMap } from "components/CheckboxFilter/CheckboxFilter";
import { NotificationDropdown } from "./NotificationDropdown";

const { SubMenu } = Menu;

interface HeaderProps {
  me: UserEntity | null;
  isAdmin: boolean;
  unreadMessages: number;
  daysUserTime: string[];

  notifications: {
    latest: PushNotificationEntity | null;
    unread: NotificationBareData[];
  };
}

interface SearchResultsParams {
  search?: string,
  tracks?: string,
}

const mapStateToProps = (state: RootState): HeaderProps => {
  return {
    me: state.userStore.byId["me"],
    isAdmin: state.authStore.isAdmin,
    daysUserTime: state.scheduleStore.daysUserTime,
    unreadMessages: state.chatStore.unreadMessages,

    notifications: {
      latest: state.pushNotificationStore.latest,
      unread: state.pushNotificationStore.unread,
    },
  };
};

export const Header = connect(mapStateToProps)((props: HeaderProps) => {
  const { dispatch, apiService } = useContext(DiContext);

  const userSrv = userService({ apiService, dispatch });
  const talkSrv = talkService({ apiService, dispatch });
  const pushNotificationSrv = pushNotificationService({ apiService, dispatch });

  const [tracks, setTracks] = useState<TrackEntity[]>([]);
 
  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as SearchResultsParams;

  const [search, setSearch] = useSessionState("search", "");
  const [selectedTracks, setSelectedTracks] = useSessionState<StringBooleanMap>("tracks", {});

  const debouncedSearch = useDebounce(search, 250);

  useEffect(() => {
    const queryTracks = (queryParams.tracks || "").split(",").filter(Boolean);
    queryTracks.forEach((trackId) => {
      if (!Object.keys(selectedTracks).includes(trackId)) {
        setSelectedTracks((prevVal) => {
          const newVal = _.cloneDeep(prevVal);
          newVal[trackId] = true;
          return newVal;
        });
      }
    });
  }, [history.location.search]);

  const transformWithoutSpaceAndUpperCase = (str: string) => str.replace(/\s/g, "").toUpperCase();

  const filter = () => {
    const params = new URLSearchParams({});

    const querySearch = queryParams.search?.toLowerCase() || "";

    if (debouncedSearch || querySearch) {
      params.append("search", debouncedSearch || querySearch);
    }

    if (debouncedSearch === "") {
      params.set("search", "");
    }

    const trackIds = Object.keys(selectedTracks).reduce((acc, track) => {
      if (selectedTracks[track]) {
        return acc ? `${acc},${track}` : track;
      }

      return acc;
    }, "");

    if (trackIds) {
      params.append("tracks", trackIds);
    }

    const searchParams = decodeURIComponent(params.toString());

    if (searchParams === "" || (debouncedSearch === "" && !Object.keys(selectedTracks).length)) {
      if (history.location.pathname === "/app/results") {
        history.push("/app/home");
      }
      return;
    }

    history.push({ pathname: "/app/results", search: searchParams });
  };

  useEffect(() => {
    const querySearch = queryParams.search?.toLowerCase() || "";
    setSearch(querySearch);
  }, []);

  useEffect(() => {
    filter();
  }, [debouncedSearch]);

  useEffect(() => {
    setTimeout(() => {
      filter();
    }, 250);
  }, [selectedTracks]);

  const allTalks = useAsync<TalkEntity[], unknown[]>(() => {
    return talkSrv.getAll().then((value) => {
      let eventTracks: TrackEntity[] = [];
      let data = uniqBy(value.map((talk) => {
        return talk.track;
      }) || [], "id");

      data = data.filter((res) => {
        if (res.name.toUpperCase().includes("AW") || res.name.toUpperCase().includes("AD WORLD")) {
          eventTracks.push(res);
        }
        return !res.name.toUpperCase().includes("AW") && !res.name.toUpperCase().includes("AD WORLD");
      }).sort((a, b) => a.name.localeCompare(b.name));
      eventTracks = eventTracks.sort((a: TrackEntity, b: TrackEntity) => a.name.localeCompare(b.name));

      // sort tracks alphabetically then the event
      const mergedTracks = [...data, ...eventTracks];
      setTracks(mergedTracks);
      return value;
    });
  });

  useOnError(allTalks.error);

  const { execute, isPending } = useAsync(userSrv.me);

  useOnMount(() => {
    execute();
    allTalks.execute();
  });

  const location = useLocation();

  useEffect(() => {
    if (
      window.navigator.serviceWorker === undefined
      || window.Notification === undefined
      || window.Notification.permission !== "granted"
    ) {
      if (props.notifications.latest) {
        pushNotificationSrv.unread.getAll({ after: props.notifications.latest.send_at }).catch(console.error);
      } else {
        pushNotificationSrv.getAll({ page: 0, count: 20 }).catch(console.error);
      }
    }
  }, [location.pathname]);

  const notificationMenuItemRender = useMemo(() => {
    return (
      <Menu.Item
        className="notification-bell"
        icon={(
          <Dropdown
            overlay={<NotificationDropdown unreadNotifications={props.notifications.unread} />}
            trigger={["click"]}
            overlayStyle={{ position: "fixed" }}
            openClassName="fixed-notifications"
          >
            <a
              href="/"
              className="ant-dropdown-link notification-indicator"
              onClick={(e) => { e.preventDefault(); }}
            >
              <Bell size={20} />
              <Badge
                count={props.notifications.unread.length}
                style={{ fontWeight: "bold", boxShadow: "none", top: "-14px", right: "-25px" }}
              />
            </a>
          </Dropdown>
        )}
      />
    );
  }, [props.notifications.unread]);

  const chatMenuItemRender = useMemo(() => {
    return (
      <Menu.Item>
        <NavLink to="/app/chats">
          <Badge
            count={props.unreadMessages}
            overflowCount={99}
            offset={[17, 0]}
            style={{ background: "#F0136A", fontWeight: "bold", boxShadow: "none" }}
          >
            <MessageCircle size={20} />
            <span style={{ verticalAlign: "middle" }}>
              Chat
            </span>
          </Badge>
        </NavLink>
      </Menu.Item>
    );
  }, [props.unreadMessages]);

  if (isPending) return null;

  const getLogo = () => {
    return (
      <Menu.Item className="header-logo">
        <NavLink to="/app/home" onClick={() => window.scroll({ top: 0, behavior: "smooth" })}>
          <Row align="middle" justify="start">
            <Col>
              <img src={logo} alt={app.name} height={40} />
            </Col>
            <Col>
              <Tag
                style={{
                  marginLeft: "10px",
                  background: "linear-gradient(223.32deg, #FF9900 0%, #F75E2E 97.23%)",
                  borderRadius: "10px",
                  fontSize: "14px",
                  height: "22px",
                  width: "57px",
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                PRIME
              </Tag>
            </Col>
          </Row>
        </NavLink>
      </Menu.Item>
    );
  };

  const getMenuItems = () => {
    return (
      <>
        <MenuItem>
          <NavLink to="/app/home">
            <Home size={20} />
            <span>Home</span>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="/app/updates">
            <Bookmark size={20} />
            <span>Updates</span>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="/app/mylist">
            <List size={20} />
            <span>My List</span>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="/app/community">
            <Users size={20} />
            <span>Community</span>
          </NavLink>
        </MenuItem>

        {props.isAdmin && (
          <Menu.Item>
            <NavLink to="/app/admin?type=SCHEDULE">
              <PieChart size={20} />
              <span style={{ verticalAlign: "middle" }}>
                Admin
              </span>
            </NavLink>
          </Menu.Item>
        )}

        <SubMenu
          className="header-user"
          style={{
            float: "right",
            border: "none",
            backgroundColor: "#181922",
          }}
          key="settings"
          popupOffset={[0, 0]}
          title={(
            <NavLink
              to={props.me ? `/app/user-profile/${props.me.old_id || props.me.id}/edit` : "/app/me"}
              style={{ position: "relative" }}
            >
              {props.me && (
                <UserAvatar user={props.me} size={32} />
              )}
            </NavLink>
          )}

        >
          <Menu.Item
            key="setting:1"
            className="profile"
          >
            <NavLink
              to={props.me ? `/app/user-profile/${props.me.old_id || props.me.id}/edit` : "/app/me"}
            >
              <Settings size={20} />
              Profile
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="setting:2"
            className="profile"
          >
            <a
              href={app.detailsURL}
              target="_blank"
              rel="noreferrer"
            >
              <HelpCircle size={20} />
              FAQ
            </a>
          </Menu.Item>

          <Menu.Item
            key="privacy"
            className="profile"
          >
            <NavLink to="/privacy">
              <Shield size={20} />
              Privacy Policy
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="terms"
            className="profile"
          >
            <NavLink to="/terms">
              <BookOpen size={20} />
              Terms and Conditions
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="setting:3"
            className="logout"
            onClick={() => { logout({ dispatch }); }}
          >
            <LogOut size={20} />
            Logout
          </Menu.Item>
        </SubMenu>

        <MenuItem style={{ float: "right", display: "flex", marginTop: "15px" }} className="search-menu-item" disabled={true}>
          <Input.Group compact={true} style={{ display: "flex", height: "40px" }}>
            <Input
              prefix={<Search size={20} color="white" />}
              placeholder="Search speech, keywords"
              className="header-input"
              style={{
                height: "100%",
                paddingTop: "5px",
                paddingBottom: "5px",
                maxWidth: "285px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />

            <div className="pro-checkbox-filter">
              <CheckboxFilter
                overlayClassName="pro-checkbox-menu"
                itemTypePlaceholder="tracks"
                placeholder="All Tracks"
                items={tracks.map((track) => {
                  return {
                    id: track.id,
                    name: track.name,
                  };
                })}
                selectedItems={selectedTracks}
                setSelectedItems={setSelectedTracks}
              />
            </div>
          </Input.Group>
        </MenuItem>
      </>
    );
  };

  return (
    <Layout.Header>
      <Menu
        mode="horizontal"
        className="pro-header"
        selectedKeys={[location.pathname]}
        style={{
          backgroundColor: "#181922",
        }}
        overflowedIndicator={<Hamburger />}
      >
        {getLogo()}
        {getMenuItems()}
      </Menu>
    </Layout.Header>
  );
});
