import React from "react";
import { Link, NavLink } from "react-router-dom";
import { app } from "config";

import Icons from "components/Icons";
import { Button, Col, Row, Tag } from "antd";
import logo from "assets/images/adw/logo/logo-dark.svg";

export const Footer = () => {
  const getLogo = () => {
    return (
      <NavLink to="/app/home" onClick={() => window.scroll({ top: 0, behavior: "smooth" })}>
        <Row align="middle" justify="start">
          <Col>
            <img src={logo} alt={app.name} height={40} />
          </Col>
          <Col>
            <Tag
              style={{
                marginLeft: "10px",
                background: "linear-gradient(223.32deg, #FF9900 0%, #F75E2E 97.23%)",
                borderRadius: "10px",
                fontSize: "14px",
                height: "22px",
                width: "57px",
                padding: "0px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              PRIME
            </Tag>
          </Col>
        </Row>
      </NavLink>
    );
  };

  return (
    <footer
      style={{
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          padding: "30px 145px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {getLogo()}

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "30px", marginBottom: "3.5px" }}>#AdWorldPrime</span>

            <a href="https://www.facebook.com/AdWorldPrime" rel="noopener noreferrer" target="_blank">
              <Icons.Facebook primaryColor="#181922" style={{ marginRight: "30px" }} />
            </a>

            <a href="https://twitter.com/AdWorldPrime" rel="noopener noreferrer" target="_blank">
              <Icons.Twitter primaryColor="#181922" style={{ marginRight: "30px" }} />
            </a>

            <a href="https://www.instagram.com/adworldprime" rel="noopener noreferrer" target="_blank">
              <Icons.Instagram primaryColor="#181922" style={{ marginRight: "30px" }} size={20} />
            </a>

            <a href="https://www.youtube.com/c/adworldconference" rel="noopener noreferrer" target="_blank">
              <Icons.YouTube primaryColor="#181922" style={{ marginRight: "30px" }} />
            </a>

            <a href="https://www.linkedin.com/company/adworldprime" rel="noopener noreferrer" target="_blank">
              <Icons.LinkedIn primaryColor="#181922" style={{ marginRight: "30px" }} size={24} />
            </a>

            <a href="https://www.tiktok.com/@adworldprime" rel="noopener noreferrer" target="_blank">
              <Icons.TikTok primaryColor="#181922" style={{ marginRight: "30px" }} size={18} />
            </a>

            <Button type="ghost" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
              Back to top ↑
            </Button>
          </div>
        </div>

        <span
          style={{
            backgroundColor: "rgba(36, 38, 45, 0.3)",
            display: "block",
            height: "1px",
            maxWidth: "1400px",
            width: "100%",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div>
              <Link to="/terms">Terms and Conditions</Link>
              <span style={{ color: "#F75E2E", paddingLeft: "5px", paddingRight: "5px" }}>•</span>
              <Link to="/privacy">Privacy Policy</Link>
              <span style={{ color: "#F75E2E", paddingLeft: "5px", paddingRight: "5px" }}>•</span>
              Contact us at: <a href="mailto:info@adworldprime.com">info@adworldprime.com</a>
            </div>

            <div>
              Powered by{" "}
              <a href="https://connectplexus.com/">
                ConnectPlexus Virtual Conference Technologies
              </a>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "auto",
            }}
          >
            Ad World is brought to you by{" "}
            <a href="https://www.istackholdings.com/" rel="noopener noreferrer" target="_blank" style={{ marginLeft: "5px" }}>iStack Conferences Ltd.</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
