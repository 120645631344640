import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppMode, mode } from "config";

import moment from "moment-timezone";
import { preventCopy } from "helpers";

import Countdown, { CountdownTimeDelta, zeroPad } from "react-countdown";

import { TrackEntity } from "app/infra/track";
import { StageEntity } from "app/infra/stage";
import { UserEntity } from "app/infra/user";

import { Tag } from "antd";
import { Clock } from "react-feather";

import { UserAvatar } from "components/User/UserAvatar";

export enum Status {
  future = 0,
  stable = 1,
}

interface TalkProps {
  id: string;
  title: string;
  time: string;
  description: string;
  speakers: UserEntity[] | undefined;
  length: number;
  track: TrackEntity | undefined;
  stage: string;
  // eslint-disable-next-line react/no-unused-prop-types
  myTalks: string[];
  start_time: string;
  // eslint-disable-next-line react/no-unused-prop-types
  offset: number;
  stages: StageEntity<string>[];
  hasDiscussion: boolean;
  isFinished: boolean;
  timezone: string;
  status: Status | null;
  hasNetworkingMixer: boolean;
  setStatusFromTalk: () => void;
}

export const Talk = (props: TalkProps): ReactElement => {
  useEffect(() => {
    props.setStatusFromTalk();
  }, [props.id]);

  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownTimeDelta) => {
    if (completed) {
      props.setStatusFromTalk();
      return "We are Live!";
    }

    if (days > 0) return `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    if (hours > 0) return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;

    return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
  };

  const startTime = moment(props.start_time).tz(props.timezone);
  const nowTime = moment(new Date()).tz(props.timezone);
  const isLive = !!(nowTime.isAfter(startTime) && !props.isFinished);
  const hasEvent = (props.hasDiscussion || props.hasNetworkingMixer);
  const tagName = () => {
    return props.hasDiscussion ? "Q&A" : "Networking Mixer";
  };

  const isOnboardingMode = mode === AppMode.onboarding;

  const isBreak = (value: string) => {
    const regex = /\b(break)$/i.exec(value) || [];
    return regex.length > 0 && value.split(" ").length < 3;
  };

  return (
    <div className="talk-wrapper">
      {/* [TODO]: uncomment before launching live
      {props.status === Status.future && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
          {props.track && (
            <Tag style={{ fontSize: "14px", textOverflow: "unset", wordBreak: "keep-all", overflow: "unset", padding: "5px 10px" }}>
              {props.track.name}
            </Tag>
          )}

          <div
            style={{
              fontSize: "18px",
              fontWeight: 800,
              color: "#230B34",
              margin: "auto",
              whiteSpace: "nowrap",
              paddingLeft: "5px",
            }}
          >
            We are going live in:
            <span className="countdown-timer">
              <Countdown
                date={Date.parse(props.start_time)}
                renderer={renderer}
                intervalDelay={0}
              />
            </span>
          </div>

          {props.track && (
            <Tag style={{ visibility: "hidden", fontSize: "16px" }}>
              {props.track.name}
            </Tag>
          )}
        </div>
      )} */}

      <h2
        style={{ fontWeight: 700 }}
        className="talk-wrapper-title"
        onCopy={preventCopy}
      >
        {props.title}
      </h2>

      <div className="talk-wrapper-details schedule-time-on-off">
        <p
          onCopy={preventCopy}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            {!isOnboardingMode && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Clock size={20} style={{ marginRight: "5px" }} />{" "}
                {moment(props.time).tz(props.timezone).format("H:mm")}
                {" "}-{" "}
                {moment(props.time).add(props.length, "minutes").tz(props.timezone).format("H:mm")}
              </div>
            )}

            {isLive && !isBreak(props.title) && (
              <Tag
                className="ant-tag-small"
                style={{
                  color: "#F01E6A",
                  background: "rgba(240, 30, 106, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px",
                }}
              >
                <svg viewBox="0 0 100 100" height="15" width="15">
                  <circle cx="50" cy="50" r="20" fill="#F01E6A" />
                  <circle cx="50" cy="50" r="40" fill="#F01E6A66" />
                </svg>
                Live
              </Tag>
            )}
            {hasEvent && (
              <Tag
                className="ant-tag-small"
                style={{
                  color: "#F01E6A",
                  background: "rgba(240, 30, 106, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px",
                }}
              >
                <svg viewBox="0 0 100 100" height="15" width="15">
                  <circle cx="50" cy="50" r="20" fill="#F01E6A" />
                  <circle cx="50" cy="50" r="40" fill="#F01E6A66" />
                </svg>
                {tagName()}
              </Tag>
            )}
          </span>
        </p>
      </div>

      <p
        className="talk-wrapper-description"
        onCopy={preventCopy}
      >
        {props.description}
      </p>

      {props.title !== "Break" && (
        <div className="talk-wrapper-footer">
          <div className="talk-speakers-wrapper">
            {props.speakers && props.speakers.map((speaker) => (
              <div className="talk-wrapper-speaker">
                <div className="talk-wrapper-speaker-img">
                  <UserAvatar user={speaker} style={{ marginRight: "15px" }} />
                </div>

                <div className="talk-wrapper-speaker-info">
                  <Link to={`/app/user-profile/${speaker.id}`} className="talk-wrapper-speaker-info-name">
                    {speaker.first_name} {speaker.last_name}
                  </Link>

                  <div className="talk-wrapper-speaker-info-job-position">
                    {speaker.job_position}
                  </div>

                  <div className="talk-wrapper-speaker-info-company">
                    {speaker.company?.name || speaker.attendee_company}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
