import React from "react";

import { useAsync, useDebounce, useOnError } from "hooks";

import { Form, Input, notification } from "antd";
import { FormInstance } from "antd/lib/form";
import { Lock } from "react-feather";

import { UpdatePassword, UserService } from "services/UserService";
import { UserEntity } from "../../app/infra/user";

interface UserPasswordFormProps {
  user: UserEntity;
  form: FormInstance;
  onFinish: () => void;
}

export const UserPasswordForm = (props: UserPasswordFormProps) => {
  const hasPassword = useDebounce(props.user.hasPassword, 1000);
  const userService = UserService();

  const { execute, isPending, error } = useAsync((data: UpdatePassword) => {
    return userService
      .passwords
      .update(props.user, data)
      .then((response) => {
        notification.success({ message: "You can use the new password to login" });
        props.form.resetFields();
        props.onFinish();

        return response;
      });
  });

  useOnError(error);

  const handleError = () => {
    notification.error({
      message: "Invalid Form",
      description: "Please fix all invalid fields to be able to save your changes",
    });
  };

  return (
    <Form
      form={props.form}
      layout="vertical"
      onFinish={execute}
      onFinishFailed={handleError}
    >
      {hasPassword && (
        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Current Password is required",
            },
          ]}
        >
          <Input.Password placeholder="Current Password" prefix={<Lock size={22} />} disabled={isPending} />
        </Form.Item>
      )}

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            min: 6,
            message: "Password should be at least 6 characters",
          },
          {
            required: true,
            message: "Password is required",
          },
        ]}
      >
        <Input.Password placeholder="New Password" prefix={<Lock size={20} />} disabled={isPending} />
      </Form.Item>

      <Form.Item
        name="passwordConfirmation"
        label="Password Confirmation"
        rules={[
          {
            required: true,
            message: "",
          },
          ({ getFieldValue }) => ({
            validator: (_, value: string) => {
              return value !== getFieldValue("password") ? Promise.reject() : Promise.resolve();
            },
            message: "Must be the same as Password",
          }),
        ]}
        validateFirst={true}
      >
        <Input.Password placeholder="New Password Confirmation" prefix={<Lock size={20} />} disabled={isPending} />
      </Form.Item>
    </Form>
  );
};
