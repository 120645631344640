import { CommonServiceDeps } from "app/common";
import { talkStore } from "app/infra/talk";
import { AxiosRequestConfig } from "axios";
import { LeadStatsDTO } from "app/presentation/admin/stats/leadComponent";
import { ResourceEntity } from "./resource.entity";

export interface CreateResourceDTO {
  id: string;
  file: string | Blob;
}

export const resourceService = ({
  apiService,
  dispatch,
}: CommonServiceDeps) => ({
  create: (data: CreateResourceDTO) => {
    const formData = new FormData();
    formData.append("file", data.file);
    // @ts-ignore
    formData.append("filename", data.file.name);
    return apiService
      .post<ResourceEntity>(`resources/${data.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const resource = { ...response };
        dispatch(talkStore.actions.addResource({ resource, talkId: data.id }));
        return response;
      });
  },
  delete: (talkId: string, resourceId: string) =>
    apiService.delete(`resources/${resourceId}`).then(() => {
      dispatch(talkStore.actions.removeResource({ talkId, resourceId }));
    }),
  download: (id: string) => apiService.post(`resources/download/${id}`),
  getResourceStats: (data: LeadStatsDTO, config: AxiosRequestConfig = {}) =>
    apiService.post<string | ArrayBuffer | ArrayBufferView | Blob>("/stats/resources", data, config),
});
