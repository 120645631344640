import React, { useContext } from "react";

import { DiContext } from "app/common";
import { useModal } from "hooks";

import { ResourceEntity, resourceService } from "app/infra/resource";

import { DownloadCloud } from "react-feather";
import { AgreeShareInformationResource } from "./agreeShareInformationResource.modal";

enum FileTypes {
  pdf = "pdf",
  xls = "xls",
  xlsx = "xlsx",
  docx = "docx",
}
interface ResourceProps {
  type: FileTypes;
  title: string;
  size: string;
  link: string;
  id: string;
}

const Resource = ({ type, title, size, link, id }: ResourceProps) => {
  const modal = useModal();
  const { apiService, dispatch } = useContext(DiContext);
  const resourceSrv = resourceService({ apiService, dispatch });

  return (
    <>
      <a
        download={link}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="resource"
        onClick={(e) => { e.preventDefault(); }}
      >
        <DownloadCloud />

        <div
          onClick={() => {
            modal.open();
          }}
        >
          <h4>{title}</h4>
          <span>{size}</span>
        </div>
      </a>

      <AgreeShareInformationResource
        visible={modal.isVisible}
        onOk={() =>
          resourceSrv.download(id).then((response) => {
            modal.shut();
            return response;
          })
        }
        close={modal.shut}
        url={link}
        title={title}
      />
    </>
  );
};

interface ResourcesProps {
  resources: ResourceEntity[];
}

export const Resources = ({ resources }: ResourcesProps) => {
  return (
    <div className="resources-container">
      {resources.map((resource, key) => (
        <Resource
          key={key}
          title={resource.file_name}
          size={resource.size}
          type={resource.file_type as FileTypes}
          link={resource.path}
          id={resource.id}
        />
      ))}
    </div>
  );
};
