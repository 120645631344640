import React, { CSSProperties } from "react";

import { Col, Row, Tag } from "antd";

import { app } from "config";
import logo from "assets/images/adw/logo/logo-dark.svg";

interface Props {
  style?: CSSProperties;
}

export const Logo = (props: Props) => {
  return (
    <Row align="middle" justify="center" style={{ marginBottom: "40px", ...props.style }}>
      <Col>
        <div className="logo" style={{ margin: 0 }}>
          <img src={logo} alt={`${app.name} Logo`} height="40" width="200" />
        </div>
      </Col>

      <Col>
        <Tag
          style={{
            marginLeft: "10px",
            background: "linear-gradient(223.32deg, #FF9900 0%, #F75E2E 97.23%)",
            borderRadius: "10px",
            fontSize: "14px",
            height: "22px",
            width: "45px",
            padding: "0px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          PRO
        </Tag>
      </Col>
    </Row>
  );
};

Logo.defaultProps = { style: undefined };
