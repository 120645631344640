import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { pusher } from "config";

import { ApiService, AppDispatch, PusherBeam, RootState, Firebase } from "services";
import { connect, DispatchProp, Matching } from "react-redux";

import { useDebounce, useOnMount } from "hooks";

import { DiContext } from "app/common";
import { logout } from "services/AuthService";

import { UserEntity } from "app/infra/user";
import { TicketTypeService } from "app/infra/ticketType";
import { ChatService } from "app/infra/chat";
import { NotificationBareData, PushNotificationStore } from "app/infra/pushNotification/pushNotification.store";

import { Layout } from "components/Layout/Layout";

import { LobbyPage, TrackPage, PrivacyPage, TermsPage, CommunityPage } from "app/presentation/dashboard";

import { PasswordRecoveryForm } from "components/User/Password/Recovery/PasswordRecoveryForm";
import { PasswordForm } from "components/User/Password/PasswordForm";
import * as Passwordless from "components/Auth/Passwordless";
import { SessionForm } from "components/Auth/Session/SessionForm";
import { UserUpdate } from "components/User/UserUpdate";
import TrackRedirect from "components/TrackRedirect";

import { notification } from "antd";

import { CurrentTalkUpdater } from "app/presentation/common/currentTalkUpdater";

import ScrollToTop from "app/presentation/scrollToTop";
import { PerkList as PerkListAdmin } from "components/@admin/Perk/PerkList";
import { PerkCreate } from "components/@admin/Perk/PerkCreate";
import { PerkUpdate } from "components/@admin/Perk/PerkUpdate";
import { Home } from "app/presentation/dashboard/home/Home";
import { MyList } from "app/presentation/dashboard/mylist/MyList";
import { SearchResults } from "app/presentation/dashboard/searchResults/SearchResults";

import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

interface RouterProps extends Matching<{ token: null } & DispatchProp, unknown> {
  token: string | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
  me: UserEntity | null;
  dispatch: AppDispatch;
}

const mapStateToProps = (state: RootState) => {
  return {
    token: state.authStore.token,
    isLoggedIn: state.authStore.isLoggedIn,
    isAdmin: state.authStore.isAdmin,
    me: state.userStore.byId["me"],
  };
};

const AdminPage = lazy(() => import("app/presentation/admin/admin.page"));

export const Router = connect(mapStateToProps)((props: RouterProps) => {
  const [expired, setExpired] = useState(false);

  const { dispatch } = props;

  const debouncedExpired = useDebounce(expired, 150);
  useEffect(() => {
    if (debouncedExpired) {
      notification.error({ message: "You were logged out, because this login was used on another device or browser." });

      setTimeout(() => { setExpired(true); }, 100);
    }
  }, [debouncedExpired]);

  const [apiService] = useMemo(() => {
    return [
      new ApiService(
        props.token || "",
        () => { logout({ dispatch }); },
        () => { setExpired(true); },
      ),
    ];
  }, [props.token]);

  const entityServices = useMemo(() => {
    const commonDeps = { dispatch, apiService };

    return {
      TicketTypeService: TicketTypeService(commonDeps),
      ChatService: ChatService(commonDeps),
    };
  }, [apiService]);

  const pusherBeamService = useMemo(() => {
    return new PusherBeam(pusher.beam.instanceId, pusher.beam.tokenProviderURL);
  }, []);

  useEffect(() => {
    if (props.isLoggedIn) {
      pusherBeamService.subscribe(props.token, pusher.beam.interest);
      Firebase.subscribeToChatPushNotifications(dispatch);
      Firebase.onMessageListener((payload: any) => {
        notification
          .info({
            message: (
              <a href={payload?.notification?.click_action || "/app/chats"}>
                {payload?.notification?.body}
              </a>
            ),
          });
      });
    } else {
      pusherBeamService.unSubscribe();
    }
  }, [props.isLoggedIn]);

  useOnMount(() => {
    if (navigator.serviceWorker) {
      const receivedPushNotification = (data: MessageEvent<NotificationBareData>) => {
        if (!data.data.isFirebaseMessaging) {
          dispatch(PushNotificationStore.actions.unread.create(data.data));
        }
      };

      navigator.serviceWorker.addEventListener("message", receivedPushNotification);
    }
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <DiContext.Provider value={{ apiService, dispatch, entityServices }}>
          <Layout>
            <>
              <ScrollToTop />
              <Switch>
                <Route path="/" exact={true}>
                  <Redirect to="/auth/login" />
                </Route>

                <Route path="/privacy">
                  <PrivacyPage />
                </Route>

                <Route path="/terms">
                  <TermsPage />
                </Route>

                <Route
                  path="/api/share/:id"
                  render={(routerProps) => <Redirect to={`/app/talk/${routerProps.match.params.id}`} />}
                />

                <PublicRoute path="/auth" isLoggedIn={props.isLoggedIn}>
                  <>
                    <Route path="/auth/password-login" component={SessionForm} />

                    <Route path="/auth/login" component={Passwordless.SessionForm} />
                    <Route path="/auth/passwordless-login-verify" component={Passwordless.Session} />

                    <Route path="/auth/reset-password" component={PasswordRecoveryForm} />
                    <Route path="/auth/confirm-reset-password" component={PasswordForm} />
                  </>
                </PublicRoute>

                <PrivateRoute path="/app" isLoggedin={props.isLoggedIn}>
                  <>
                    <Route path="/app/user-profile/:id/edit" component={UserUpdate} />

                    <Route path="/app/talk/:id" component={TrackPage} />
                    <Route path="/app/track/:id" component={TrackRedirect} />

                    <Route path="/app/updates" component={LobbyPage} />
                    <Route path="/app/updates/post/:id" component={LobbyPage} />

                    {props.isAdmin && (
                      <>
                        <Route path="/app/admin" component={AdminPage} exact={true} />
                        <Route path="/app/admin/perks" component={PerkListAdmin} exact={true} />
                        <Route path="/app/admin/perks/new" component={PerkCreate} exact={true} />
                        <Route path="/app/admin/perks/:id/edit" component={PerkUpdate} exact={true} />
                      </>
                    )}

                    <CurrentTalkUpdater />

                    <Route path="/app/home" component={Home} />
                    <Route path="/app/mylist" component={MyList} />
                    <Route path="/app/results" component={SearchResults} />
                    <Route path="/app/community" component={CommunityPage} />
                  </>
                </PrivateRoute>
              </Switch>
            </>
          </Layout>
        </DiContext.Provider>
      </BrowserRouter>
    </Suspense>
  );
});
