import React, { useEffect, useMemo, useRef, useState } from "react";

import { useDebounce } from "hooks";

import { Checkbox, Dropdown, Input, Menu } from "antd";
import { ChevronDown, ChevronUp, Search, XOctagon } from "react-feather";

export interface StringBooleanMap {
  [key: string]: boolean;
}

interface Item {
  id: string | number;
  name: string;
  children?: Item[] | null;
}

interface FilterProps {
  overlayClassName?: string;
  itemTypePlaceholder?: string;
  placeholder: string;
  items: Item[];
  selectedItems: StringBooleanMap;
  setSelectedItems: (obj: StringBooleanMap) => void;
  searchable?: boolean;
}

const CheckboxFilter = (props: FilterProps) => {
  const toggleCheckItem = (id: string) => {
    props.setSelectedItems({
      ...props.selectedItems,
      [id]: props.selectedItems[id] ? !props.selectedItems[id] : true,
    });
  };

  const [items, setItems] = useState(props.items);
  const [itemsVisible, setItemsVisible] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const debouncedFilter = useDebounce(search, 200);
  const ref = useRef<Input | null>(null);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  useEffect(() => {
    if (typeof debouncedFilter === "string" && debouncedFilter.trim() !== "") {
      setItems(props.items.filter((item) => {
        return item.name.toLowerCase().search(debouncedFilter.trim().toLowerCase()) !== -1;
      }));
    } else {
      setItems(props.items);
    }
  }, [debouncedFilter]);

  const numberOfItems = useMemo(() => {
    return Object.values(props.selectedItems).filter(Boolean).length;
  }, [props.selectedItems]);

  const selectedItemsArray = useMemo(() => {
    return Object.keys(props.selectedItems).filter((key) => props.selectedItems[key]);
  }, [props.selectedItems]);

  const selectedItemsName = useMemo(() => {
    return props.items.filter((item) => item.id.toString() === selectedItemsArray[0]);
  }, [props.items, props.selectedItems]);

  const headers = useMemo(() => ({
    0: props.placeholder,
    1: (
      <span>
        {selectedItemsArray.length} {props.itemTypePlaceholder || "items"}
      </span>
    ),
  }), [props.placeholder, selectedItemsName, selectedItemsArray]);

  const getHeaderItems = () => {
    return (
      <div style={{ display: "flex", paddingLeft: "1rem", alignItems: "center" }}>
        {numberOfItems > 0 && (
          <XOctagon
            style={{ marginRight: 10, position: "initial", flexShrink: 0, backgroundColor: "transparent" }}
            size={18}
            color="white"
            onClick={() => props.setSelectedItems({})}
          />
        )}

        <span
          style={{
            marginRight: "auto",
            color: "#A3A7B2",
            fontSize: "14px",
            overflow: "hidden",
          }}
        >
          {headers[selectedItemsArray.length as keyof typeof headers] || headers[1]}
        </span>

        {itemsVisible ? (
          <ChevronUp size={20} style={{ flexShrink: 0, marginRight: "10px" }} />
        ) : (
          <ChevronDown size={20} style={{ flexShrink: 0, marginRight: "10px" }} />
        )}
      </div>
    );
  };

  const dropdownItems = (
    <Menu className="column-checkbox">
      <div
        className="spacer-div"
        style={{
          backgroundColor: "#d9d9d9",
          width: "100%",
          height: "1px",
          marginBottom: "1rem",
        }}
      />
      {props.searchable && (
        <Input
          ref={ref}
          className="search"
          placeholder="Search"
          suffix={<Search size={18} />}
          onChange={(event) => setSearch(event.target.value)}
        />
      )}

      {items.map((item: Item) => (
        <>
          {item.children && (
            <div>{item.name}</div>
          )}

          {item.children ? (
            item.children.map((child: Item) => (
              <Menu.Item key={`track-menu-item-${child.id}`}>
                <Checkbox
                  key={`track-menu-checkbox-${item.id}`}
                  checked={props.selectedItems[child.id]}
                  onChange={() => {
                    toggleCheckItem(child.id.toString());
                  }}
                >
                  {child.name}
                </Checkbox>
              </Menu.Item>
            ))
          ) : (
            <Menu.Item key={`track-menu-item-${item.id}`}>
              <Checkbox
                key={`track-menu-checkbox-${item.id}`}
                checked={props.selectedItems[item.id]}
                onChange={() => {
                  toggleCheckItem(item.id.toString());
                }}
              >
                {item.name}
              </Checkbox>
            </Menu.Item>
          )}
        </>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      className="filters-dropdown"
      overlayClassName={`filters-dropdown-content ${props.overlayClassName || ""}`}
      visible={itemsVisible}
      overlay={dropdownItems}
      trigger={["click"]}
      onVisibleChange={(visible) => {
        if (visible) {
          setTimeout(() => ref.current?.focus(), 200);
        }
        setItemsVisible(visible);
      }}
    >
      {getHeaderItems()}
    </Dropdown>
  );
};

CheckboxFilter.defaultProps = {
  searchable: false,
  overlayClassName: undefined,
  itemTypePlaceholder: undefined,
};
export default CheckboxFilter;
