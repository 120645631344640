import { CommonServiceDeps, PaginationDTO } from "app/common";
import { userStore } from "./user.store";
import { UserEntity } from "./user.entity";

interface UpdateConnectionsDataDTO {
  connectionsLeft: number;
  dailyLimit: number;
}

export interface AdminCreateUserDTO {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  password?: string;
  company: string;
  bio: string;
  company_id?: string;
}

export interface GetUsersDTO extends PaginationDTO {
  search?: string;
  pageSize?: number;
}

export interface AdminEditUserDTO extends AdminCreateUserDTO {}

export const adminUserService = ({
  dispatch,
  apiService,
}: CommonServiceDeps) => ({
  getAll: ({ page, pageSize, search }: GetUsersDTO) =>
    apiService.get("admin/users", {
      params: {
        page,
        count: pageSize,
        ...((search && { search }) || {}),
      },
    }),
  create: (data: AdminCreateUserDTO) =>
    apiService.post("admin/create-user", data).then((response) => {
      const user = response as UserEntity;
      dispatch(userStore.actions.addUsers({ resources: [user] }));
      return user;
    }),
  update: (data: AdminEditUserDTO, id: string) =>
    apiService.patch(`admin/update-user/${id}`, data).then((response) => {
      dispatch(
        userStore.actions.addUsers({ resources: [response as UserEntity] })
      );
      return response;
    }),
  getSpeakers: () =>
    apiService.get("/admin/speakers").then((speakers) => {
      dispatch(userStore.actions.addUsers({ resources: speakers }));
      return speakers;
    }),
  deletePhoto: (id: string) =>
    apiService.delete(`admin/picture/${id}`).then((response) => {
      const user = { ...response, id } as UserEntity;
      dispatch(userStore.actions.addUsers({ resources: [user] }));
      return user;
    }),
  updatePhoto: (photo: string | Blob, id: string) => {
    const formData = new FormData();
    formData.append("picture", photo);
    return apiService
      .patch(`admin/picture/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const user = { ...response, id } as UserEntity;
        dispatch(userStore.actions.addUsers({ resources: [user] }));
        return user;
      });
  },

  updateTicket: (id: string, deactivate_sync: boolean) =>
    apiService.patch(`admin/tickets/${id}`, {
      deactivate_sync,
    }),
  getUser: (id: string) => apiService.get<UserEntity>(`admin/users/${id}`),
  updateConnectionsData: (data: UpdateConnectionsDataDTO, userId: string) =>
    apiService.patch<void>(`admin/users/${userId}/connection-limit`, data),
  syncTicket: (ticketId: string) =>
    apiService.post(`admin/tickets/${ticketId}`),
});
