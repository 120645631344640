import React from "react";
import { app } from "config";

import { TalkEntity } from "app/infra/talk";

import { Button, Dropdown, Menu } from "antd";
import { Send } from "react-feather";

interface ActionsProps {
  talk: TalkEntity;
  customText?: string;
}

export const Actions = (props: ActionsProps) => {
  const link = `${app.apiUrl as string}/share/${props.talk.id}`;

  return (
    <div className="actions">
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=${link}&title=${props.talk.title}`}
              >
                Facebook
              </a>
            </Menu.Item>

            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://twitter.com/share?text=${props.talk.title}&url=${link}&hashtags=AdWorld2022`}
              >
                Twitter
              </a>
            </Menu.Item>

            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${props.talk.title}`}
              >
                Linkedin
              </a>
            </Menu.Item>
          </Menu>
        )}
      >
        <Button type="link" size="small" className="ant-dropdown-trigger">
          <span className="share-text">{props.customText}</span>
          <Send size={20} style={{ cursor: "pointer", verticalAlign: "middle", marginLeft: "5px" }} />
        </Button>
      </Dropdown>
    </div>
  );
};

Actions.defaultProps = { customText: "Share" };
