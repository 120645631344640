import Check from "./CheckIcon";
import CloudDownloadAlt from "./CloudDownloadAltIcon";
import LinkedIn from "./LinkedinIcon";
import Facebook from "./FacebookIcon";
import FacebookSimple from "./FacebookSimpleIcon";
import Twitter from "./TwitterIcon";
import SyncCalendar from "./SyncCalendarIcon";
import Pin from "./PinIcon";
import TickRound from "./TickRound";
import TikTok from "./TikTok";
import Instagram from "./Instagram";
import YouTube from "./YouTube";

export {
  Check,
  CloudDownloadAlt,
  LinkedIn,
  Facebook,
  FacebookSimple,
  Twitter,
  SyncCalendar,
  Pin,
  TickRound,
  TikTok,
  Instagram,
  YouTube,
};

const Icons = Object.freeze({
  Check,
  CloudDownloadAlt,
  LinkedIn,
  Facebook,
  FacebookSimple,
  Twitter,
  SyncCalendar,
  Pin,
  TickRound,
  TikTok,
  Instagram,
  YouTube,
});

export default Icons;
