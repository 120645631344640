import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const TikTok = (props: BasicProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.size,
        height: props.size,
        position: props.position,
        fill: props.primaryColor,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      viewBox="0 0 20 20"
    >
      <path
        d="M14.0198 3.42827C12.671 2.3205 12.813 0.10498 12.813 0.10498H9.58957V7.94625V13.4171C9.58957
          15.0017 8.30535 16.2859 6.72075 16.2859C5.13614 16.2859 3.85193 15.0017 3.85193 13.4171C3.85193 11.8325
          5.13614 10.5482 6.72075 10.5482C6.95745 10.5482 7.18812 10.5775 7.40847 10.6317V7.27832C7.18296 7.25336
          6.95314 7.23959 6.72075 7.23959C3.30881 7.23959 0.543274 10.0051 0.543274 13.4171C0.543274 16.829 3.30881
          19.5945 6.72075 19.5945C10.0965 19.5945 12.838 16.8858 12.8956 13.5238C12.8956 13.5238 12.8982 13.504 12.8974
          13.4644C12.8974 13.4489 12.8982 13.4326 12.8982 13.4171C12.8982 13.3181 12.8948 13.2208 12.8905
          13.1235C12.8698 11.9994 12.813 8.7407 12.813 6.25405C14.8013 8.69681 17.4567 8.00047 17.4567 8.00047C17.4567
          8.00047 17.4567 6.5949 17.4567 4.66342C15.8376 4.66428 15.3694 4.53603 14.0198 3.42827Z"
      />
    </svg>
  );
};

TikTok.defaultProps = {
  size: 25,
  primaryColor: "#fff",
};

export default TikTok;
