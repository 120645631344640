import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const YouTube = (props: BasicProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.size,
        height: props.size,
        position: props.position,
        fill: props.primaryColor,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      viewBox="0 0 33 32"
    >
      <path
        d="M16.5752 7.60791C13.5131 7.60791 9.53861 8.37515 9.53861 8.37515L9.52861 8.38658C8.13394 8.60963
          7.06543 9.80806 7.06543 11.2655V15.6547V15.6561V20.0438V20.0452C7.06679 20.7413 7.31625 21.4141
          7.76901 21.9428C8.22177 22.4715 8.84817 22.8215 9.53575 22.9299L9.53861 22.9342C9.53861 22.9342
          13.5131 23.7029 16.5752 23.7029C19.6374 23.7029 23.6119 22.9342 23.6119 22.9342L23.6133
          22.9328C24.3016 22.8246 24.9287 22.4743 25.3818 21.945C25.835 21.4157 26.0843 20.742 26.085
          20.0452V20.0438V15.6561V15.6547V11.2655C26.084 10.5692 25.8347 9.89608 25.3819 9.36708C24.9291
          8.83809 24.3025 8.4879 23.6147 8.37944L23.6119 8.37515C23.6119 8.37515 19.6374 7.60791 16.5752
          7.60791ZM14.3807 12.2885L20.2329 15.6547L14.3807 19.0208V12.2885Z"
      />
    </svg>
  );
};

YouTube.defaultProps = {
  size: 33,
  primaryColor: "#fff",
};

export default YouTube;
