import firebase from "firebase";
import "firebase/messaging";

import { chatStore } from "app/infra/chat";
import { AppDispatch } from "./store";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyCvTCr8q8Kxh6zEAXvgHkCbP3yLzY89wOM",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:671811492347:web:7a041ec6cc7c824c58135e",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || "671811492347",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "adw4-chat-production",
});

const message = firebase.messaging.isSupported() ? firebase.messaging() : null;

const subscribeToChatPushNotifications = (dispatch: AppDispatch) => {
  if (message) {
    message.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
        || "BJ4BxFGu59pP-wJOZOwk7ah98W8eI_7fddeK2X0oxR4s428gZdRWFHkTopAxFePpYsj7RVXUC45WXUi1UPamXBc",
    })
      .then((fcmToken: string) => {
        if (fcmToken) {
          dispatch(chatStore.actions.update({ fcmToken }));
        }
      })
      .catch(console.error);
  }
};

const onMessageListener = (cb: (payload: any) => void) =>
  message?.onMessage((payload) => {
    cb(payload);
  });

export const Firebase = {
  subscribeToChatPushNotifications,
  onMessageListener,
};
