import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import _, { uniqBy } from "lodash";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { scheduleService } from "app/infra/schedule";
import { TalkEntity, talkService } from "app/infra/talk";
import { TrackEntity } from "app/infra/track";

import { Button, Card, Col, Modal, Row, Tag } from "antd";
import { PlayCircle, PlusCircle, Trash } from "react-feather";

import { UserAvatar } from "components/User/UserAvatar";

import { parse } from "qs";

interface TalkListModalProps {
  visible: boolean,
  talk?: TalkEntity,
  onClose: () => void,
}

interface SearchResultsParams {
  search?: string,
  tracks?: string,
}

const TalkListModal = connect(
  (state: RootState, ownProps: TalkListModalProps) => ({
    myTalks: state.scheduleStore.myTalks,
    props: ownProps,
  }),
)(
  ({
    myTalks,
    props,
  }: { props: TalkListModalProps, myTalks: string[] }) => {
    const history = useHistory();

    if (!props.talk) return null;

    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });

    const { isPending, execute, error } = useAsync((add: boolean) => {
      return add
        ? scheduleSrv.addTalkToMySchedule(props.talk?.id || "")
        : scheduleSrv.removeTalkFromMySchedule(props.talk?.id || "");
    });

    useOnError(error);

    return (
      <Modal
        visible={props.visible}
        onCancel={props.onClose}
        footer={null}
        title={null}
        width="40%"
        className="pro-modal"
        bodyStyle={{
          padding: "60px",
        }}
      >
        <div>
          <Tag
            style={{
              background: "linear-gradient(180deg, #FDFD5E -9.52%, #F7C82E 100%)",
              borderRadius: "10px",
              fontSize: "14px",
              color: "black",
            }}
          >
            {props.talk.track.name}
          </Tag>

          <h1 style={{ marginTop: "10px" }}>
            {props.talk.title}
          </h1>
        </div>

        <Row>
          <Col>
            {props.talk.speakers && (<UserAvatar user={props.talk?.speakers[0]} />) }
          </Col>

          <Col style={{ marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>
            {props.talk.speakers && (
              <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                <span>
                  {props.talk.speakers[0].first_name} {props.talk.speakers[0].last_name}
                </span>

                <span>
                  {props.talk.speakers[0].job_position},
                  {props.talk.speakers[0].company?.name
                  || props.talk.speakers[0].attendee_company}
                </span>
              </div>
            )}
          </Col>
        </Row>

        <Row style={{ margin: "10px 0px" }}>
          <p>
            {props.talk.description}
          </p>
        </Row>

        <Row justify="start" align="middle" gutter={10}>
          <Col>
            <Button
              className="pro-ant-btn"
              type="primary"
              icon={<PlayCircle size={20} style={{ marginRight: "10px" }} />}
              onClick={() => history.push(`/app/talk/${props.talk!.id}`)}
              disabled={!props.talk.isAvailable}
              style={{ pointerEvents: props.talk.isAvailable ? "unset" : "none" }}
            >
              Watch Talk
            </Button>
          </Col>

          <Col>
            <Button
              type="default"
              icon={
                myTalks.includes(props.talk?.id || "") ? (
                  <Trash size={20} style={{ marginRight: "10px" }} />
                ) : (
                  <PlusCircle size={20} style={{ marginRight: "10px" }} />
                )
              }
              disabled={isPending}
              loading={isPending}
              onClick={() => execute(!myTalks.includes(props.talk?.id || ""))}
              className="pro-ant-btn"
            >
              {myTalks.includes(props.talk?.id || "") ? "Remove From My List" : "Add To My List"}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  },
);

const TalkListImage = connect(
  (state: RootState, ownProps: { talk: TalkEntity, onClick: () => void }) => ({
    talk: ownProps.talk,
    myTalks: state.scheduleStore.myTalks,
    onClick: ownProps.onClick,
  }),
)(
  ({
    talk,
    myTalks,
    onClick,
  }: { talk: TalkEntity, myTalks: string[], onClick: () => void }) => {
    const [hovered, setHovered] = useState(false);
    const history = useHistory();

    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });

    const { isPending, execute, error } = useAsync((add: boolean) => {
      return add
        ? scheduleSrv.addTalkToMySchedule(talk.id)
        : scheduleSrv.removeTalkFromMySchedule(talk.id);
    });

    useOnError(error);

    return (
      <Col
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        xs={24}
        sm={24}
        md={12}
        xl={8}
        xxl={6}
      >
        <Card
          style={{ borderRadius: 9 }}
          bodyStyle={{ padding: 0, cursor: "pointer" }}
        >
          <img
            src={talk.cover_url}
            alt={talk.title}
            style={{ borderRadius: "8px", width: "100%" }}
            onClick={onClick}
            aria-hidden="true"
          />
          {(hovered && talk.isAvailable) && (
            <div
              style={{
                display: "flex",
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "50px",
                justifyContent: "space-between",
                backdropFilter: "blur(24px)",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                alignItems: "center",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <Button
                type="ghost"
                icon={<PlayCircle size={18} style={{ marginRight: "5px" }} />}
                className="pro-ant-btn"
                style={{
                  padding: "5px",
                  color: "white",
                  marginLeft: "10px",
                  fontSize: 13,
                }}
                onClick={() => history.push(`/app/talk/${talk.id}`)}
              >
                {`Watch talk ${talk.length ? `(${talk.length} min)` : ""}`}
              </Button>

              <Button
                icon={
                  myTalks.includes(talk.id) ? (
                    <Trash size={18} style={{ marginRight: "5px" }} />
                  ) : (
                    <PlusCircle size={18} style={{ marginRight: "5px" }} />
                  )
                }
                type="ghost"
                disabled={isPending}
                loading={isPending}
                onClick={() => execute(!myTalks.includes(talk.id))}
                className="pro-ant-btn"
                style={{
                  padding: "5px",
                  color: "white",
                  marginRight: "10px",
                  fontSize: 13,
                }}
              >
                {myTalks.includes(talk.id) ? "Remove From My List" : "Add To My List"}
              </Button>
            </div>
          )}
        </Card>
      </Col>
    );
  },
);

const TalkList = (props: { talks: TalkEntity[] }) => {
  const [visible, setVisible] = useState(false);
  const [talkSelected, setTalkSelected] = useState<undefined | TalkEntity>(undefined);

  const openTalk = (talk: TalkEntity) => {
    setTalkSelected(talk);
    setVisible(true);
  };

  const closeTalk = () => {
    setVisible(false);
    setTalkSelected(undefined);
  };

  return (
    <div style={{ margin: "20px" }}>
      <Row
        gutter={[10, 10]}
        className="ant-row ant-row-middle"
        style={{ minHeight: 220 }}
      >
        {props.talks.sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)).map(
          (talk) => <TalkListImage talk={talk} onClick={() => openTalk(talk)} />,
        )}
      </Row>
      {talkSelected && <TalkListModal visible={visible} talk={talkSelected} onClose={closeTalk} />}
    </div>
  );
};

export const SearchResults = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const talkSrv = talkService({ apiService, dispatch });

  const [talks, setTalks] = useState<TalkEntity[]>([]);
  const [tracksData, setTracks] = useState<TrackEntity[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searchIsTrack, setSearchIsTrack] = useState<boolean>(false);

  const allTalks = useAsync<TalkEntity[], unknown[]>(() => {
    return talkSrv.getAll().then((value) => {
      let eventTracks: TrackEntity[] = [];
      let data = uniqBy(value.map((talk) => {
        return talk.track;
      }) || [], "id");

      data = data.filter((res) => {
        if (res.name.toUpperCase().includes("AW") || res.name.toUpperCase().includes("AD WORLD")) {
          eventTracks.push(res);
        }
        return !res.name.toUpperCase().includes("AW") && !res.name.toUpperCase().includes("AD WORLD");
      }).sort((a, b) => a.name.localeCompare(b.name));
      eventTracks = eventTracks.sort((a: TrackEntity, b: TrackEntity) => a.name.localeCompare(b.name));

      // sort tracks alphabetically then the event
      const mergedTracks = [...data, ...eventTracks];
      setTracks(mergedTracks);
      return value;
    });
  });
  useOnError(allTalks.error);
  useOnMount(allTalks.execute);

  const allTracks = allTalks.value?.reduce((acc: { [key: string]: string }, talk) => ({
    ...acc,
    [talk.track.id]: talk.track.name,
  }), {});

  const history = useHistory();

  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as SearchResultsParams;

  const transformWithoutSpaceAndUpperCase = (str: string) => str.replace(/\s/g, "").toUpperCase();

  useEffect(() => {
    const talkList = allTalks.value || [];
    const tracks = queryParams.tracks?.split(",") || [];
    let searchQuery = queryParams.search?.toLowerCase() || "";

    let result = talkList;

    const tracksName = tracksData.map((res) => transformWithoutSpaceAndUpperCase(res.name));
    if (tracksName.includes(transformWithoutSpaceAndUpperCase(searchQuery))) {
      const res = tracksData.find((data) => {
        return transformWithoutSpaceAndUpperCase(data.name)
          === transformWithoutSpaceAndUpperCase(searchQuery);
      });

      if (res) {
        searchQuery = "";
        tracks.push(res.id.toString());
        setSearchIsTrack(true);
      }
    }

    if (tracks && tracks[0]) {
      result = result.filter((talk) => talk.tracks.some((track) => tracks.includes(track.id.toString())));
    }

    if (searchQuery) {
      result = result.filter((talk) => {
        const speakers = talk.speakers?.map((x) => `${x.first_name} ${x.last_name}`).join(", ") || "";
        return [
          talk.title,
          talk.description,
          speakers,
        ].some((x) => x.toLowerCase().includes(searchQuery));
      });
    }

    setSearch(searchQuery);
    setTalks(result);
  }, [history.location.search, allTalks.value]);

  return (
    <div style={{ margin: "20px" }}>
      <Row style={{ margin: "20px 20px 0 20px" }}>
        {search ? (
          <h1>{search.toUpperCase()}</h1>
        ) : (
          <h1 style={{ textTransform: "capitalize" }}>
            {(searchIsTrack && queryParams.search ? `${queryParams.search}${queryParams.tracks ? "," : ""} ` : "")}
            {(queryParams.tracks || "").split(",").map((trackId) => (allTracks || {})[trackId]).join(", ")}
          </h1>
        )}
      </Row>

      {queryParams.search && (
        <Row>
          <h4 style={{ marginLeft: "20px" }}>
            Related:
          </h4>

          {_.sampleSize(Object.keys(allTracks || {}), 5).map((trackId) => (
            <Link
              to={`/app/results?tracks=${trackId || ""}`}
              style={{
                color: "#F75E2E",
                margin: "3.5px 7px",
                cursor: "pointer",
              }}
            >
              {(allTracks || {})[trackId]}
            </Link>
          ))}
        </Row>
      )}
      <TalkList talks={talks} />
    </div>
  );
};
