import React, { useMemo } from "react";

import { useAsync } from "app/common/utils";
import { useOnError } from "hooks";

import { Upload } from "antd";
import { PlusCircle } from "react-feather";

import { Loading } from "components/Loading/Loading";

export interface FileFormFunctionProps {
  file: string | Blob;
}

interface HasImageUrl {
  image_url?: string;
}

interface UploadPhotoProps {
  image_url?: string;
  uploadPhoto: (args: FileFormFunctionProps) => Promise<unknown>;
}

export const UploadPhoto = ({ image_url, uploadPhoto }: UploadPhotoProps) => {
  const { execute, pending, value, error } = useAsync<HasImageUrl>(uploadPhoto, false);

  const cached_image = useMemo(() =>
    value?.image_url || image_url,
    [image_url, value]
  );

  useOnError(error);

  return (
    <div>
      <Upload
        className={`uploadable-profile-photo ${!cached_image && "hasnt-photo"}`}
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        customRequest={execute}
        accept=".png,.jpg,.jpeg"
      >
        {cached_image ? (
          <div
            style={{
              position: "relative",
            }}
          >
            {pending && (
              <Loading
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
            <img src={cached_image} alt="avatar" style={{ width: "100%" }} />
          </div>
        ) : (
          <div>
            {pending ? <Loading /> : <PlusCircle size={20} />}
            <div className="ant-upload-text">Upload</div>
          </div>
        )}
      </Upload>
      {pending && <span>Uploading, please wait..</span>}
    </div>
  );
};
