import React, { useContext, useEffect, useMemo, useState } from "react";

import { DiContext } from "app/common";
import { useAsync, useDebounce, useOnError } from "hooks";

import { companyService } from "app/infra/company";
import { Perk, PerkEntity, perkService } from "app/infra/perk";

import { UploadPhoto } from "app/presentation/common";

import { FormInstance } from "antd/lib/form";
import { Form, Input, InputNumber, Select, Spin } from "antd";
import { uniqBy } from "lodash";

interface Props {
  form: FormInstance,
  onFinish: (data: Perk) => void,
  perk?: PerkEntity,
}

export const PerkForm = (props: Props) => {
  const { apiService, dispatch } = useContext(DiContext);
  const perkSrv = perkService({ apiService, dispatch });
  const companySrv = companyService({ apiService, dispatch });

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const { execute, isPending, value, error } = useAsync(() => {
    return companySrv.getCompanies({
      search: debouncedSearch || undefined,
      component: "user-company-search",
      limit: 10,
      page: 0,
    });
  });
  useOnError(error);

  useEffect(() => {
    execute();
  }, [debouncedSearch]);

  const upload = ({ file }: { file: string | Blob }) => {
    return perkSrv.updatePhoto({ id: props.perk?.id || "", photo: file });
  };

  const companies = useMemo(() => {
    return uniqBy([...(value?.data || []), ...(props.perk?.company ? [props.perk.company] : [])], "id");
  }, [value]);

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      layout="vertical"
      scrollToFirstError={true}
      initialValues={props.perk}
    >
      <Form.Item
        name="rank"
        label="Rank"
        rules={[
          {
            type: "number",
            message: "Rank must be a valid number",
          },
        ]}
      >
        <InputNumber placeholder="e.g: 10" />
      </Form.Item>

      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: "Description is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="link"
        label="Link"
        rules={[
          {
            type: "url",
            message: "Link must be a valid URL",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="default_call_to_action"
        label="Default Call To Action"
        rules={[
          {
            required: true,
            message: "Default Call To Action is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="claimed_call_to_action"
        label="Claimed Call To Action"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="price_new"
        label="Price New"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="price_old"
        label="Price Old"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="company_id"
        label="Company"
        initialValue={props.perk?.company?.id}
      >
        <Select
          allowClear={true}
          showSearch={true}
          placeholder="Select Company"
          filterOption={false}
          notFoundContent={isPending ? <Spin size="small" /> : null}
          onSearch={(val) => setSearch(val)}
        >
          {companies.map((company) => (
            <Select.Option value={company.id} key={company.id}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {props.perk ? (
        <UploadPhoto uploadPhoto={upload} image_url={props.perk.image_url} />
      ) : (
        "Image for perk can be uploaded only on edit, not on create!"
      )}
    </Form>
  );
};

PerkForm.defaultProps = {
  perk: undefined,
};
