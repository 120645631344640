import React from "react";
import { Redirect, Route } from "react-router-dom";

interface PublicRouteProps {
  path: string;
  children: React.ReactChild;
  isLoggedIn: boolean;
}

export const PublicRoute = (props: PublicRouteProps) => {
  return (
    <Route
      path={props.path}
      render={({ location }) => {
        return !props.isLoggedIn ? props.children : (
          <Redirect to={{ pathname: "/app/home", state: { from: location } }} />
        );
      }}
    />
  );
};
