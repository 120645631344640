import React from "react";

import { useIubenda } from "hooks";

import { Button } from "antd";
import { ChevronRight } from "react-feather";
import communityPNG from "assets/images/adw/pro/community.png";

export const CommunityPage = () => {
  useIubenda();

  return (
    <div className="community-container">
      <h1 className="community-title">
        Join thousands of likeminded marketers<br />
        in the official Ad World Community
      </h1>

      <p className="community-text">
        Ask questions, share ideas and build connections with each other.
      </p>

      <Button
        type="primary"
        href="https://www.facebook.com/groups/adworldcommunity"
        target="_blank"
        rel="noopener noreferrer"
        className="pro-ant-btn community-button"
      >
        Join Ad World Facebook Group <ChevronRight size={22} color="white" style={{ opacity: 0.6, marginLeft: 10 }} />
      </Button>

      <p style={{ flexGrow: 1 }} className="flex-gap" />

      <img
        src={communityPNG}
        alt="Ad World Community"
        className="community-banner"
      />
    </div>
  );
};
