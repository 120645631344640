import React from "react";
import { Link } from "react-router-dom";

import { TalkEntityExtended } from "app/infra/talk";
import { UserEntity } from "app/infra/user";

import { Tag } from "antd";

import { UserAvatar } from "components/User/UserAvatar";

interface PostProps {
  text: string;
  speaker?: string;
  time?: string;
  length?: number;
  stage?: string | undefined;
  to?: string;
  trackName: string;
  user?: UserEntity;
}

const Post = ({ text, speaker, to, user, trackName }: PostProps) => {
  if (to) {
    return (
      <Link to={to} className="post">
        {user && (
          <div style={{ marginRight: "15px" }}>
            <UserAvatar user={user} />
          </div>
        )}

        <div className="post-info">
          <div className="post-header">
            <h6 className="post-info-speaker">{speaker}</h6>

            <Tag
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: 164,
                display: "inline-block",
                lineHeight: "20px",
              }}
              className={`ant-tag-small ${trackName}`}
            >
              {trackName}
            </Tag>
          </div>

          <p className="post-info-title" title={text}>
            {text}
          </p>
        </div>
      </Link>
    );
  }

  return (
    <div className="post">
      {user && (
        <div style={{ marginRight: "15px" }}>
          <UserAvatar user={user} />
        </div>
      )}

      <p>{text}</p>
    </div>
  );
};

interface ActivityProps {
  nextTalk?: TalkEntityExtended[];
  streamingNow?: TalkEntityExtended[];
  currentTalk?: TalkEntityExtended;
  contentContainerHeight?: number;
}

export const Activity = ({ nextTalk, contentContainerHeight }: ActivityProps) => {
  return (
    <div className="activity-container" style={{ height: contentContainerHeight || "100vh" }}>
      <div className="extra-activity-container">
        <div className="upcoming-talks-container">
          <div className="title">
            <span>YOU MIGHT LIKE</span>
          </div>

          <div>
            {nextTalk && nextTalk.map((nextTalk) => (
              <Post
                user={nextTalk.speakers![0]}
                text={nextTalk.title}
                speaker={`${
                  nextTalk?.speakers?.length
                    ? nextTalk.speakers[0].first_name +
                      " " +
                      nextTalk.speakers[0].last_name
                    : ""
                }`}
                to={nextTalk.id.toString()}
                trackName={nextTalk?.track?.name}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
