import React, { ReactElement } from "react";
import BasicProps from "./BasicProps";

const Instagram = (props: BasicProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.size,
        height: props.size,
        position: props.position,
        fill: props.primaryColor,
        cursor: props.onClick && "pointer",
        ...props.style,
      }}
      viewBox="0 0 20 20"
    >
      <path
        d="M6.03607 0.339844C2.97788 0.339844 0.490234 2.82979 0.490234 5.88878V13.8136C0.490234
          16.8718 2.98018 19.3595 6.03917 19.3595H13.964C17.0222 19.3595 19.5098 16.8695 19.5098
          13.8105V5.88568C19.5098 2.82749 17.0199 0.339844 13.9609 0.339844H6.03607ZM15.5474 3.50978C15.9849
          3.50978 16.3399 3.86481 16.3399 4.30226C16.3399 4.73971 15.9849 5.09475 15.5474 5.09475C15.11 5.09475
          14.7549 4.73971 14.7549 4.30226C14.7549 3.86481 15.11 3.50978 15.5474 3.50978ZM10 5.09475C12.6224
          5.09475 14.7549 7.22732 14.7549 9.84965C14.7549 12.472 12.6224 14.6045 10 14.6045C7.37771 14.6045
          5.24514 12.472 5.24514 9.84965C5.24514 7.22732 7.37771 5.09475 10 5.09475ZM10 6.67971C9.15932
          6.67971 8.35303 7.01369 7.75856 7.60816C7.16408 8.20264 6.8301 9.00893 6.8301 9.84965C6.8301
          10.6904 7.16408 11.4967 7.75856 12.0911C8.35303 12.6856 9.15932 13.0196 10 13.0196C10.8408
          13.0196 11.647 12.6856 12.2415 12.0911C12.836 11.4967 13.17 10.6904 13.17 9.84965C13.17 9.00893 12.836
          8.20264 12.2415 7.60816C11.647 7.01369 10.8408 6.67971 10 6.67971V6.67971Z"
      />
    </svg>
  );
};

Instagram.defaultProps = {
  size: 25,
  primaryColor: "#fff",
};

export default Instagram;
