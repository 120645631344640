import React, { useEffect, useState } from "react";

import { useOnError, useAsync } from "hooks";
import { UserService } from "services/UserService";

import { Button, Form, Input, notification } from "antd";
import { User } from "react-feather";
import { Logo } from "components/Pro/Logo";

export const PasswordRecoveryForm = () => {
  const [successMessage, setSuccessMessage] = useState(false);

  const { execute, isPending, value, error } = useAsync(UserService().passwordRecoveries.create);
  useOnError(error);

  useEffect(() => {
    if (value && value.ok) {
      notification.success({
        message: "Please check your email to reset your password. Make sure to check your spam folder.",
      });

      setSuccessMessage(true);
    }
  }, [value]);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container pro-card">
        <Form
          layout="vertical"
          onFinish={execute}
        >
          <Logo />

          {successMessage && (
            <div className="ant-row ant-form-item">
              <strong>Your reset password email is on the way!</strong>
              It can take up to 5-10 minutes to arrive, please check your spam folder,
              before contacting support for additional assistance.
            </div>
          )}

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Not a valid email address",
              },
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email"
              prefix={<User size={20} />}
              className="pro-input"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isPending}
              block={true}
              style={{
                borderRadius: "23px",
              }}
            >
              Send reset password link
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
