import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { app } from "config";

import { useAsync, useOnError } from "hooks";
import { AuthService } from "services/AuthService";

import { Button, Col, Form, Input, notification, Row, Tag } from "antd";
import logo from "assets/images/adw/logo/logo-dark.svg";
import { ChevronLeft, ChevronRight } from "react-feather";

export const SessionForm = () => {
  const { execute, value, isPending, error } = useAsync(AuthService().passwordless.requests.create);
  useOnError(error);

  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (value && value.ok) {
      notification.success({
        message: "Please check your email to login. Make sure to check your spam folder.",
      });

      setSuccessMessage(true);
    }
  }, [value]);

  return (
    <div className="auth-wrapper">
      <div
        className="auth-wrapper-form-container"
        style={{ borderRadius: "23.31px" }}
      >
        <Form
          layout="vertical"
          onFinish={execute}
          className="auth-form"
        >
          <Row align="middle" justify="center" style={{ marginBottom: "40px" }}>
            <Col>
              <div className="logo" style={{ margin: 0 }}>
                <img src={logo} alt={`${app.name} Logo`} height="40" width="200" />
              </div>
            </Col>

            <Col>
              <Tag
                style={{
                  marginLeft: "10px",
                  background: "linear-gradient(223.32deg, #FF9900 0%, #F75E2E 97.23%)",
                  borderRadius: "10px",
                  fontSize: "14px",
                  height: "22px",
                  width: "45px",
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                PRO
              </Tag>
            </Col>
          </Row>


          {successMessage && (
            <div className="ant-row ant-form-item">
              <strong>Your login email is on the way!</strong>
              It can take a moment to arrive, please check your spam folder,
              before contacting support for additional assistance.
            </div>
          )}

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Enter email"
              style={{
                borderRadius: "9.23px",
                lineHeight: "23px",
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              block={true}
              loading={isPending}
              style={{
                borderRadius: "9.23px",
                background: "linear-gradient(to right, #5EFFD8, #60E2FF)",
                color: "black",
                boxShadow: "0px 5px 70px 1px #60E2FF"
              }}
            >
              Get login link
            </Button>
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Link to="/auth/password-login">
              <Button
                type="default"
                block={false}
                style={{
                  borderRadius: "23px",
                  color: "#F75E2E",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "#F75E2E",
                }}
              >
                Login with email & password
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </div>

      <Button
        type="primary"
        block={true}
        style={{ boxShadow: "0px 30px 50px rgba(19, 23, 76, 0.5)", maxWidth: 300, height: 45, borderRadius: 5 }}
      >
        <a href="https://adworldconference.com/pro" target="_blank" rel="noreferrer" style={{ fontWeight: 900, fontSize: 15 }}>
          <ChevronRight style={{ verticalAlign: "middle", float: "left" }} />
          JOIN AD WORLD PRO NOW
          <ChevronLeft style={{ verticalAlign: "middle", float: "right" }} />
        </a>
      </Button>
    </div>
  );
};
