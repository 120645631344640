import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import _ from "lodash";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { scheduleService } from "app/infra/schedule";
import { TalkEntity, talkService } from "app/infra/talk";

import { Button, Card, Col, Modal, Row, Tag } from "antd";
import { PlayCircle, PlusCircle, Trash } from "react-feather";

import { UserAvatar } from "components/User/UserAvatar";

import noTalksImg from "assets/images/no-my-talks.svg";

interface TalkListModalProps {
  visible: boolean;
  talk?: TalkEntity;
  onClose: () => void;
}
const TalkListModal = connect(
  (state: RootState, ownProps: TalkListModalProps) => ({
    myTalks: state.scheduleStore.myTalks,
    props: ownProps,
  }),
)(
  ({
    myTalks,
    props,
  }: { props: TalkListModalProps, myTalks: string[] }) => {
    const history = useHistory();

    if (!props.talk) return null;

    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });

    const { isPending, execute, error } = useAsync((add: boolean) => {
      return add
        ? scheduleSrv.addTalkToMySchedule(props.talk?.id || "")
        : scheduleSrv.removeTalkFromMySchedule(props.talk?.id || "");
    });

    useOnError(error);

    return (
      <Modal
        visible={props.visible}
        onCancel={props.onClose}
        footer={null}
        title={null}
        width="40%"
        className="pro-modal"
        bodyStyle={{
          padding: "60px",
        }}
      >
        <div>
          <Tag
            style={{
              background: "linear-gradient(180deg, #FDFD5E -9.52%, #F7C82E 100%)",
              borderRadius: "10px",
              fontSize: "14px",
              color: "black",
            }}
          >
            {props.talk.track.name}
          </Tag>

          <h1 style={{ marginTop: "10px" }}>
            {props.talk.title}
          </h1>
        </div>

        <Row>
          <Col>
            {props.talk.speakers && (<UserAvatar user={props.talk?.speakers[0]} />) }
          </Col>

          <Col style={{ marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>
            {props.talk.speakers && (
              <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                <span>
                  {props.talk.speakers[0].first_name} {props.talk.speakers[0].last_name}
                </span>

                <span>
                  {props.talk.speakers[0].job_position},
                  {props.talk.speakers[0].company?.name
                  || props.talk.speakers[0].attendee_company}
                </span>
              </div>
            )}
          </Col>
        </Row>

        <Row style={{ margin: "10px 0px" }}>
          <p>
            {props.talk.description}
          </p>
        </Row>

        <Row justify="start" align="middle" gutter={10}>
          <Col>
            <Button
              className="pro-ant-btn"
              type="primary"
              icon={<PlayCircle size={20} style={{ marginRight: "10px" }} />}
              onClick={() => history.push(`/app/talk/${props.talk!.id}`)}
              disabled={!props.talk.isAvailable}
              style={{ pointerEvents: props.talk.isAvailable ? "unset" : "none" }}
            >
              Watch Talk
            </Button>
          </Col>

          <Col>
            <Button
              type="default"
              icon={
                myTalks.includes(props.talk?.id || "") ? (
                  <Trash size={20} style={{ marginRight: "10px" }} />
                ) : (
                  <PlusCircle size={20} style={{ marginRight: "10px" }} />
                )
              }
              disabled={isPending}
              loading={isPending}
              onClick={() => execute(!myTalks.includes(props.talk?.id || ""))}
              className="pro-ant-btn"
            >
              {myTalks.includes(props.talk?.id || "") ? "Remove From My List" : "Add To My List"}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  },
);

const TalkListImage = connect(
  (state: RootState, ownProps: { talk: TalkEntity, onClick: () => void }) => ({
    talk: ownProps.talk,
    myTalks: state.scheduleStore.myTalks,
    onClick: ownProps.onClick,
  }),
)(
  ({
    talk,
    myTalks,
    onClick,
  }: { talk: TalkEntity, myTalks: string[], onClick: () => void }) => {
    const [hovered, setHovered] = useState(false);
    const history = useHistory();

    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });

    const { isPending, execute, error } = useAsync((add: boolean) => {
      return add
        ? scheduleSrv.addTalkToMySchedule(talk.id)
        : scheduleSrv.removeTalkFromMySchedule(talk.id);
    });

    useOnError(error);

    return (
      <Col
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        xs={24}
        sm={24}
        md={12}
        xl={8}
        xxl={6}
      >
        <Card
          style={{ borderRadius: 9 }}
          bodyStyle={{ padding: 0, cursor: "pointer" }}
        >
          <img
            src={talk.cover_url}
            alt={talk.title}
            style={{ borderRadius: "8px", width: "100%" }}
            onClick={onClick}
            aria-hidden="true"
          />
          {(hovered && talk.isAvailable) && (
            <div
              style={{
                display: "flex",
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "50px",
                justifyContent: "space-between",
                backdropFilter: "blur(24px)",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                alignItems: "center",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <Button
                type="ghost"
                icon={<PlayCircle size={18} style={{ marginRight: "5px" }} />}
                className="pro-ant-btn"
                style={{
                  padding: "5px",
                  color: "white",
                  marginLeft: "10px",
                  fontSize: 13,
                }}
                onClick={() => history.push(`/app/talk/${talk.id}`)}
              >
                Watch talk
              </Button>

              <Button
                icon={<Trash size={18} style={{ marginRight: "5px" }} />}
                type="ghost"
                disabled={isPending}
                loading={isPending}
                onClick={() => execute(!myTalks.includes(talk.id))}
                className="pro-ant-btn"
                style={{
                  padding: "5px",
                  color: "white",
                  marginRight: "10px",
                  fontSize: 13,
                }}
              >
                Remove From My List
              </Button>
            </div>
          )}
        </Card>
      </Col>
    );
  },
);

const TalkList = (props: { talks: TalkEntity[] }) => {
  const [visible, setVisible] = useState(false);
  const [talkSelected, setTalkSelected] = useState<undefined | TalkEntity>(undefined);

  const openTalk = (talk: TalkEntity) => {
    setTalkSelected(talk);
    setVisible(true);
  };

  const closeTalk = () => {
    setVisible(false);
    setTalkSelected(undefined);
  };

  return (
    <div style={{ margin: "20px" }}>
      <Row style={{ margin: "20px 20px 0 5px" }}>
        <h1>My List</h1>
      </Row>

      <Row
        gutter={[10, 10]}
        className="ant-row ant-row-middle"
        style={{ minHeight: 220 }}
      >
        {props.talks.sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)).map(
          (talk) => <TalkListImage talk={talk} onClick={() => openTalk(talk)} />,
        )}
      </Row>
      {talkSelected && <TalkListModal visible={visible} talk={talkSelected} onClose={closeTalk} />}
    </div>
  );
};

export const MyList = connect(
  (state: RootState) => ({
    myTalks: state.scheduleStore.myTalks,
  }),
)(
  ({
    myTalks,
  }: { myTalks: string[] }) => {
    const history = useHistory();

    const { apiService, dispatch } = useContext(DiContext);
    const talkSrv = talkService({ apiService, dispatch });

    const talks = useAsync(talkSrv.getAll);
    useOnError(talks.error);
    useOnMount(talks.execute);

    const scheduleSrv = scheduleService({ apiService, dispatch });

    const getMyTalks = useAsync<TalkEntity[], unknown[]>(scheduleSrv.getMySchedule);
    useOnError(getMyTalks.error);
    useOnMount(getMyTalks.execute);

    const myListTalks = _.compact(myTalks.map((talkId) => (talks.value || []).find((x) => x.id === talkId)));

    if (talks.isPending || !talks.value) return null;

    return (
      <div className="my-list-container">
        {myListTalks.length > 0 ? (
          <TalkList talks={myListTalks} />
        ) : (
          <div style={{ margin: "20px" }}>
            <Row style={{ margin: "20px 20px 0 5px" }}>
              <h1>My List</h1>
            </Row>

            <div style={{
              margin: "20px auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "30px",
              textAlign: "center",
            }}
            >
              <img src={noTalksImg} alt="" />
              <span style={{
                color: "#181922",
                opacity: 0.5,
                fontSize: 22,
              }}
              >
                You haven’t added any titles to your list yet.
              </span>

              <Button
                className="pro-ant-btn"
                type="primary"
                onClick={() => history.push("/app/home")}
              >
                Explore speeches
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  },
);
