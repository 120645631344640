import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { DiContext } from "app/common";
import { Perk, perkService } from "app/infra/perk";
import { useAsync, useOnError, useOnMount } from "hooks";

import { Button, Card, Col, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";

import { PerkForm } from "./PerkForm";

export const PerkUpdate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const perkSrv = perkService({ apiService, dispatch });

  const [form] = useForm();
  const history = useHistory();
  const params = useRouteMatch().params as { id: string };

  const { execute, error, value: perk } = useAsync(() => {
    return perkSrv.getOne(params.id);
  });
  useOnError(error);

  useOnMount(execute);

  const { execute: onUpdate, error: onUpdateError } = useAsync((data: Perk) => {
    return perkSrv.update(data, params.id).then((response) => {
      history.push("/app/admin/perks");

      notification.success({ message: "Perk was successfully updated" });
      return response;
    });
  });
  useOnError(onUpdateError);

  if (!perk) return null;

  return (
    <Row style={{ margin: "20px" }}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Update Perk</h1>
            </Col>
          </Row>

          <PerkForm form={form} onFinish={onUpdate} perk={perk} />

          <Row style={{ marginTop: "20px" }}>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>Update</Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
