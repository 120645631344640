import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import {
  addMinutes,
  addSeconds,
  compareAsc,
  differenceInSeconds,
  isAfter,
  isBefore,
} from "date-fns";

import { ClickedOnceProvider, DiContext, EPubSubTable, isCountryInAsia, Tracking } from "app/common";

import { Button, Tabs, Tooltip } from "antd";
import { ChevronRight } from "react-feather";

import { Status, TalkEntityExtended } from "app/infra/talk";
import { TrackEntity } from "app/infra/track";
import { StreamingProvider, videoStreamStore } from "app/infra/videoStream";

import VimeoPlayer from "@vimeo/player";
import { Player } from "app/common/views";
import { PlayerService } from "app/common/views/player/playerService";
import { country_list } from "app/common/data";
import { ForcePlay } from "app/common/views/player/forcePlay";

import { Resources } from "./resources";
import { SpeakerInformation } from "./speakerInformation";
import { Actions } from "./actions";

const ProviderDescription = {
  [StreamingProvider.VIMEO]: "Vimeo provider.",
  [StreamingProvider.JW]: "JW provider (best for Asian countries).",
  [StreamingProvider.S3]: "AWS provider.",
  [StreamingProvider.DACAST]: "Dacast provider (best for Asian countries).",
};

const ProviderName = {
  [StreamingProvider.VIMEO]: "VIMEO",
  [StreamingProvider.JW]: "JW",
  [StreamingProvider.S3]: "S3",
  [StreamingProvider.DACAST]: "DACAST",
};

const defaultProviders = [
  StreamingProvider.VIMEO,
  StreamingProvider.JW,
  StreamingProvider.S3,
  StreamingProvider.DACAST,
] as StreamingProvider[];

const asiaProviders = [
  StreamingProvider.JW,
  StreamingProvider.VIMEO,
  StreamingProvider.S3,
  StreamingProvider.DACAST,
] as StreamingProvider[];

const createVimeoPlayer = (id: string, controls: boolean, url: string) => {
  try {
    if (!VimeoPlayer) {
      return dummyPlayer;
    }

    const player = new VimeoPlayer("stage-player", {
      title: false,
      url,
      controls,
      height: 500,
      autoplay: false,
    });

    return {
      play: (offset, volume) => {
        try {
          if (offset) player?.setCurrentTime(offset);
          player?.play();
          player?.setVolume(volume / 100);
        } catch (e) {
          console.error(e);
        }
      },
      pause: () => {
        try {
          player?.pause();
        } catch (e) {
          console.error(e);
        }
      },
      currentTime: async () => {
        return player?.getCurrentTime().then((time: any) => parseInt(time));
      },
      isPlaying: () => {
        return false;
      },
      setVolume: (volume: number) => {
        try {
          player?.setVolume(volume / 100);
        } catch (e) {
          console.error(e);
        }
      },
    } as PlayerService;
  } catch (e) {
    return dummyPlayer;
  }
};

interface ContentProps {
  talk: TalkEntityExtended;
  lastUpdate: Date;
  nextTalk: TalkEntityExtended[] | undefined;
  selectedTrackEntity?: TrackEntity;
  me: any;
  volume: number;
  isMuted: boolean;
  selectedProvider: StreamingProvider,
}

const dummyPlayer = {
  play: () => {},
  pause: () => {},
  currentTime: async () => {
    return 0;
  },
  isPlaying: () => false,
  setVolume: () => {},
} as PlayerService;

const createJwPlayer = (playerId: string, controls: boolean, link: string) => {
  try {
    // @ts-ignore
    if (!window.jwplayer) {
      return dummyPlayer;
    }

    // @ts-ignore
    const player = window.jwplayer(playerId).setup({
      file: link,
      height: 500,
      controls,
    });

    return {
      play: (offset: number, volume: number) => {
        try {
          if (offset) player.seek(offset);
          player.play();
          player.setVolume(volume);
        } catch (e) {
          console.error(e);
        }
      },
      pause: () => {
        try {
          player.stop();
        } catch (e) {
          console.error(e);
        }
      },
      currentTime: async () => {
        return player?.getCurrentTime();
      },
      isPlaying: () => false,
      setVolume: (volume: number) => {
        try {
          player?.setVolume(volume);
        } catch (e) {
          console.error(e);
        }
      },
    } as PlayerService;
  } catch (e) {
    return dummyPlayer;
  }
};

const createDacastPlayer = ({
  playerId,
  link,
}: {
  playerId: string;
  controls: boolean;
  link: string;
}) => {
  try {
    // @ts-ignore
    if (!window.dacast) {
      return dummyPlayer;
    }

    // @ts-ignore
    const player = window.dacast(link, playerId);

    return {
      play: (offset: number, volume: number) => {
        try {
          player.play();
          player.volume(volume / 100);
          if (offset) player.currentTime(offset);
        } catch (e) {
          console.error(e);
        }
      },
      pause: () => {
        try {
          player.pause();
        } catch (e) {
          console.error(e);
        }
      },
      currentTime: async () => {
        return new Promise((resolve) => {
          player?.getTime((time: any) => {
            resolve(parseInt(time));
          });
        });
        // return player?.getCurrentTime();
      },
      isPlaying: () => {
        try {
          return player.playing();
        } catch (e) {
          console.error(e);
        }
      },
      setVolume: (volume: number) => {
        try {
          player?.volume(volume / 100);
        } catch (e) {
          console.error(e);
        }
      },
    } as PlayerService;
  } catch (e) {
    return dummyPlayer;
  }
};

export const Content = connect((state: RootState) => ({
  me: state.userStore.byId.me,
  volume: state.videoStreamStore.volume,
  isMuted: state.videoStreamStore.isMuted,
  selectedProvider: state.videoStreamStore.provider,
}))(
  ({
    talk,
    lastUpdate,
    nextTalk,
    selectedTrackEntity,
    me,
    volume,
    isMuted: isMutedFromStore,
    selectedProvider,
  }: ContentProps) => {
    const [status, setStatus] = useState<Status | null>(null);
    const [hasDoneAutoselect, setHasDoneAutoselect] = useState(false);
    const [offset, setOffset] = useState(talk.offset);
    const [firstPaint, setFirstPaint] = useState(true);
    const [player, setPlayer] = useState<PlayerService>(dummyPlayer);
    const { clicked } = useContext(ClickedOnceProvider);
    const { dispatch } = useContext(DiContext);

    const [showButton, setShowButton] = useState(false);

    const [providerActive, setProvider] = useState([
      {
        name: "VIMEO",
        is_loaded: false,
      },
      {
        name: "JW",
        is_loaded: false,
      },
      {
        name: "S3",
        is_loaded: false,
      },
    ]);
    const history = useHistory();

    const changeStreamByCountry = useCallback(
      (country: string) => {
        if (country_list.length && country) {
          const isCountry = country_list.includes(country);

          if (isCountry && !hasDoneAutoselect) {
            if (isCountryInAsia(country)) {
              dispatch(videoStreamStore.actions.update({ provider: StreamingProvider.JW }));
              setHasDoneAutoselect(true);
            }
          }
        }
      },
      [hasDoneAutoselect],
    );

    const handlePrrovidersActive = (index: any, value: boolean) => {
      let dataProviders = [...providerActive];
      dataProviders[index].is_loaded = value;
      setProvider(dataProviders);
    };

    useEffect(() => {
      const getCurrentCountry = me?.country;
      changeStreamByCountry(getCurrentCountry);

      let eventName: string = "";
      const eventProps: any = {
        videoId: talk.id,
        category: StreamingProvider[selectedProvider],
      };
      if (status !== null) {
        eventName = status === Status.streaming ? "" : "Video View";
      }
      let isViewing: boolean = false;

      const showControls = status === Status.finished;

      if (player) {
        player.pause();
      }

      let loadSeconds = 0;
      if (firstPaint) {
        setTimeout(() => {
          setFirstPaint(false);
        }, 100);

        setPlayer(dummyPlayer);
      } else if (status !== Status.finished && !clicked) {
        console.info(
          "Can't create player as no click happened from the user yet."
        );
        setPlayer(dummyPlayer);
      } else if (status === null) {
        setPlayer(dummyPlayer);
      } else if (selectedProvider === StreamingProvider.JW) {
        setPlayer(
          createJwPlayer(
            "stage-player",
            showControls,
            talk.video_url_4,
          ),
        );

        isViewing = true;
      } else if (selectedProvider === StreamingProvider.S3) {
        setPlayer(
          createJwPlayer(
            "stage-player",
            showControls,
            talk.video_url_3,
          ),
        );

        isViewing = true;
      } else if (selectedProvider === StreamingProvider.VIMEO) {
        setPlayer(
          createVimeoPlayer(
            "stage-player",
            status === Status.finished,
            talk.video_url_2,
          ),
        );

        isViewing = true;
      } else if (selectedProvider === StreamingProvider.DACAST) {
        setPlayer(
          createDacastPlayer({
            link: talk.video_url,
            playerId: "stage-player",
            controls: showControls,
          }),
        );
        isViewing = true;
      } else {
        setPlayer(dummyPlayer);
      }

      // if (isViewing) {
      //   Tracking.PubSub([{
      //     table: EPubSubTable.Video,
      //     data: {
      //       datetime: Tracking.Date,
      //       user_id: me.old_id || "",
      //       type: "live",
      //       platform: ProviderName[selectedProvider],
      //       timestamp: 0,
      //       video_id: talk.id,
      //     },
      //   }]);
      // }

      let checkLoadTimeVideo: any = setInterval(() => {
        loadSeconds += 1;
        if (loadSeconds >= 5 || status === 0) {
          return clearInterval(checkLoadTimeVideo);
        } else {
          handlePrrovidersActive(0, true);
        }
      }, 1000);

      return () => clearInterval(checkLoadTimeVideo);
    }, [selectedProvider, talk, firstPaint, status, clicked]);

    const currentTime = useCallback(() => {
      const diffInSeconds = differenceInSeconds(new Date(), lastUpdate);
      return addSeconds(
        addSeconds(new Date(talk.start_time), talk.offset),
        diffInSeconds
      );
    }, [lastUpdate, talk.start_time, talk.offset]);

    const setStatusFromTalk = useCallback(() => {
      const current = currentTime();

      if (talk.show_controls) {
        setStatus(Status.finished);
      } else if (isBefore(current, new Date(talk.start_time))) {
        setStatus(Status.future);
      } else if (
        isBefore(current, addMinutes(new Date(talk.start_time), talk.length)) &&
        isAfter(current, addSeconds(new Date(talk.start_time), -2))
      ) {
        setStatus(Status.streaming);
      } else if (
        isAfter(
          current,
          addSeconds(addMinutes(new Date(talk.start_time), talk.length), -10)
        ) &&
        isBefore(
          current,
          addMinutes(new Date(talk.start_time), talk.length + 10)
        )
      ) {
        setStatus((prevStatus) => {
          if (
            prevStatus === Status.streaming ||
            prevStatus === Status.loadingNextTalk
          ) {
            return Status.loadingNextTalk;
          }

          return Status.onehourfinished;
        });
      } else {
        setStatus((prevStatus) => {
          if (
            prevStatus === Status.streaming ||
            prevStatus === Status.loadingNextTalk
          ) {
            return Status.loadingNextTalk;
          }

          return Status.finished;
        });
      }
    }, [talk.id]);

    const nextTalkEntity = useMemo(() => {
      return (nextTalk || [])
        .filter((tlk) => tlk.stage_id === talk.stage?.id)
        .sort((a, b) =>
          compareAsc(new Date(a.start_time), new Date(b.start_time))
        )
        .find((item) => item.id);
    }, [nextTalk]);

    useEffect(() => {
      if (!nextTalkEntity && status === Status.loadingNextTalk) {
        setStatus(Status.onehourfinished);
      }
    }, [nextTalkEntity, status]);

    useEffect(() => {
      if (status === Status.loadingNextTalk) {
        const timeout = setTimeout(() => {
          if (nextTalkEntity) {
            history.push(`/app/talk/${nextTalkEntity.id}`);
          }
        }, 10000);

        return () => {
          clearTimeout(timeout);
        };
      }

      return () => {};
    }, [status, nextTalkEntity]);

    useEffect(() => {
      if (talk.video_url && talk.video_url_2) {
      } else if (talk.video_url) {
        dispatch(videoStreamStore.actions.update({ provider: StreamingProvider.JW }));
      } else if (talk.video_url_2) {
        dispatch(videoStreamStore.actions.update({ provider: StreamingProvider.VIMEO }));
      }

      const interval = setInterval(() => {
        setOffset(
          differenceInSeconds(currentTime(), new Date(talk.start_time))
        );
        setStatusFromTalk();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }, [talk.id]);

    useEffect(() => {
      if (status === Status.streaming) {
        const timeout = setTimeout(() => {
          player.currentTime().then((time) => {
            if (time !== 0) {
              // @ts-ignore
              dataLayer.push({
                event: "Video View",
                category: StreamingProvider[selectedProvider],
                id: `${talk.id} - ${time}`,
              });

              Tracking.PubSub([{
                table: EPubSubTable.Video,
                data: {
                  datetime: Tracking.Date,
                  user_id: Tracking.User?.id || "",
                  type: "live",
                  platform: ProviderName[selectedProvider],
                  timestamp: Math.trunc(time),
                  video_id: talk.id,
                },
              }]);
            }
          });
        }, 3_000);

        const interval = setInterval(() => {
          player.currentTime().then((time) => {
            if (time !== 0) {
              // @ts-ignore
              dataLayer.push({
                event: "Video View",
                category: StreamingProvider[selectedProvider],
                id: `${talk.id} - ${time}`,
              });

              Tracking.PubSub([{
                table: EPubSubTable.Video,
                data: {
                  datetime: Tracking.Date,
                  user_id: Tracking.User?.id || "",
                  type: "live",
                  platform: ProviderName[selectedProvider],
                  timestamp: Math.trunc(time),
                  video_id: talk.id,
                },
              }]);
            }
          });
        }, 30_000);

        return () => {
          clearTimeout(timeout);
          clearInterval(interval);
        };
      }
    }, [talk.id, status, player, selectedProvider]);

    const playDacastIfNotPlaying = useCallback(() => {
      if (
        !player.isPlaying() &&
        status === Status.streaming &&
        selectedProvider === StreamingProvider.DACAST
      ) {
        player.play(offset, volume);
      }
    }, [selectedProvider, player, status]);

    useEffect(() => {
      const dacastSyncInterval = setInterval(playDacastIfNotPlaying, 1000);
      return () => clearInterval(dacastSyncInterval);
    }, [selectedProvider, player]);

    useEffect(() => {
      if (status === Status.onehourfinished && player) {
        player.pause();
      }
    }, [status]);

    const selectedStreamingProviderOrder = useMemo(() => {
      return (
        (isCountryInAsia(me?.country) && asiaProviders) || defaultProviders
      );
    }, [me?.country]);

    const statusResponse = (s: Status, event = "qa") => {
      const response = {
        [Status.streaming]: event === "qa" ? "Submit your Live Q&A Questions" : "Access Networking Mixer",
        [Status.onehourfinished]: event === "qa" ? "Live Q&A Post-Presentation" : "Access Networking Mixer",
        [Status.finished]: event === "qa" ? "Live Q&A Post-Presentation" : "Access Networking Mixer",
        [Status.future]: null,
        [Status.loadingNextTalk]: null,
      };
      return response[s];
    };

    const liveEventStatus = (s: Status, event = "qa") => {
      return (
        [Status.streaming, Status.onehourfinished, Status.finished].includes(s)
        && (
          <div className="qa-button-container">
            <Link
              className="qa-button"
              to={`/app/chats/${talk.chat_channel_id}`}
              target="_blank"
            >
              <Button type="primary">
                <span>
                  {statusResponse(s, event)}
                </span>{" "}
                <ChevronRight size={20} />
              </Button>
            </Link>
          </div>
        ));
    };

    const play = useCallback(() => {
      try {
        if (isMutedFromStore) {
          player?.play(offset, 0);
        } else {
          player?.play(offset, volume);
        }
      } catch (e) {
        console.error("Play threw exception", e);
      }
    }, [player, offset, volume, isMutedFromStore]);

    useEffect(() => {
      if (talk.video_url) {
        setShowButton(true);
      }
    }, [talk.video_url]);

    return (
      <div className="content-container">
        <h2 style={{ marginBottom: 20 }}>
          {talk.title}
        </h2>

        <div
          key={selectedProvider}
          id="player-container"
          style={{
            height: 500,
            background: "black",
          }}
        >
          <Player
            showPlay={!clicked}
            player={player}
            videoId={talk.video_url}
            offset={(offset > talk.length && 0) || offset}
            status={status}
            identifier={talk.id}
            cover={talk.cover_url}
            stage={talk.stage}
            nextTalk={nextTalkEntity}
            onComplete={() => {
              if (nextTalkEntity) {
                history.push(`/app/talk/${nextTalkEntity.id}`);
              }
            }}
          />
        </div>
        <div className="additional-info-container">
          {status === Status.streaming && showButton && (
            <ForcePlay play={play} setShowButton={setShowButton} />
          )}

          {talk.video_url &&
            talk.video_url_2 &&
            status !== Status.future &&
            status !== Status.onehourfinished && (
              <div className="stream-list-wrapper">
                <div className="stream-list">
                  {selectedStreamingProviderOrder.map((provider, index) => (
                    <Tooltip
                      className={
                        (selectedProvider === provider && "selected") || ""
                      }
                      title={ProviderDescription[provider]}
                    >
                      <div className="stream-box" onClick={() => dispatch(videoStreamStore.actions.update({ provider }))}>
                        Stream {index + 1}
                      </div>
                    </Tooltip>
                  ))}
                </div>
                <Actions talk={talk} customText="Share" />
              </div>
            )}
          {selectedTrackEntity && talk.has_discussion && (status !== null) && liveEventStatus(status)}
          {selectedTrackEntity && talk.hasNetworkingMixer && (status !== null) && liveEventStatus(status, "mixer")}
          {talk.resources && talk.resources.length > 0 && (
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane
                key="1"
                tab={(
                  <>
                    Limited Time Presentation Resource - Download Now{" "}
                    <span role="presentation" aria-hidden="true">⬇️</span>
                  </>
                )}
              >
                <Resources resources={talk.resources || []} />
              </Tabs.TabPane>
            </Tabs>
          )}

          <p style={{ fontSize: 16, margin: "20px 30px", color: "#000" }} className="talk-desc">
            {talk.description}
          </p>

          {talk.speakers?.map((user) => (
            <div style={{ margin: "30px 0" }} key={user.id}>

              <SpeakerInformation
                user={user}
                fullname={`${user.first_name} ${user.last_name}`}
                position={`${user.job_position || ""}, ${user.company?.name || user.attendee_company || ""} `}
                bio={user.bio || ""}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
);
