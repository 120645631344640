import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "services";
import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { PerkEntity, perkService } from "app/infra/perk";

import { ColumnsType } from "antd/lib/table";
import { Button, Card, Col, notification, Popconfirm, Row, Table } from "antd";
import { PenTool, Trash } from "react-feather";

const mapStateToProps = (state: RootState) => ({
  perks: Object.values(state.perkStore.byId),
});

const connector = connect(mapStateToProps);
type PropsRedux = ConnectedProps<typeof connector>;

export const PerkList = connector((props: PropsRedux) => {
  const { apiService, dispatch } = useContext(DiContext);
  const perkSrv = perkService({ apiService, dispatch });

  const history = useHistory();

  const { execute, error } = useAsync(() => {
    return perkSrv.getAll();
  });
  useOnError(error);

  useOnMount(execute);

  const { execute: onDelete, error: onDeleteError } = useAsync((id: string) => {
    return perkSrv.delete(id).then((response) => {
      notification.success({ message: "Perk was successfully deleted" });
      return response;
    });
  });
  useOnError(onDeleteError);

  const columns: ColumnsType<PerkEntity> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "",
      width: 100,
      render: (_, record) => {
        return (
          <Button.Group>
            <Button type="link" size="small" onClick={() => history.push(`/app/admin/perks/${record.id}/edit`)}>
              <PenTool />
            </Button>

            <Popconfirm
              title="Are you certain that you want to delete this perk?"
              onConfirm={() => onDelete(record.id)}
              okText="Confirm"
              cancelText="Cancel"
              placement="topRight"
            >
              <Button type="link" size="small">
                <Trash />
              </Button>
            </Popconfirm>
          </Button.Group>
        );
      },
    },
  ];

  return (
    <Card style={{ margin: "20px" }}>
      <Row style={{ marginBottom: "20px" }}>
        <Col style={{ marginLeft: "auto" }}>
          <Button type="primary" onClick={() => history.push("/app/admin/perks/new")}>
            Create Perk
          </Button>
        </Col>
      </Row>

      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={props.perks}
        pagination={false}
      />
    </Card>
  );
});
