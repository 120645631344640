import React, { useContext, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import _ from "lodash";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { scheduleService } from "app/infra/schedule";
import { TalkEntity, talkService } from "app/infra/talk";

import { Button, Col, Modal, Row, Tag } from "antd";
import { ChevronRight, PlayCircle, PlusCircle, Trash } from "react-feather";

import { UserAvatar } from "components/User/UserAvatar";
import ScrollContainer from "react-indiana-drag-scroll";

interface TalkListModalProps {
  visible: boolean;
  talk?: TalkEntity;
  onClose: () => void;
}
const TalkListModal = connect(
  (state: RootState, ownProps: TalkListModalProps) => ({
    myTalks: state.scheduleStore.myTalks,
    props: ownProps,
  }),
)(
  ({
    myTalks,
    props,
  }: { props: TalkListModalProps, myTalks: string[] }) => {
    const history = useHistory();

    if (!props.talk) return null;

    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });

    const { isPending, execute, error } = useAsync((add: boolean) => {
      return add
        ? scheduleSrv.addTalkToMySchedule(props.talk?.id || "")
        : scheduleSrv.removeTalkFromMySchedule(props.talk?.id || "");
    });

    useOnError(error);

    return (
      <Modal
        visible={props.visible}
        onCancel={props.onClose}
        footer={null}
        title={null}
        width="40%"
        className="pro-modal"
        bodyStyle={{
          padding: "60px",
        }}
      >
        <div>
          <Tag
            style={{
              background: "linear-gradient(180deg, #FDFD5E -9.52%, #F7C82E 100%)",
              borderRadius: "10px",
              fontSize: "14px",
              color: "black",
            }}
          >
            {props.talk.track.name}
          </Tag>

          <h1 style={{ marginTop: "10px" }}>
            {props.talk.title}
          </h1>
        </div>

        <Row>
          <Col>
            {props.talk.speakers && (<UserAvatar user={props.talk?.speakers[0]} />) }
          </Col>

          <Col style={{ marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>
            {props.talk.speakers && (
              <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                <span>
                  {props.talk.speakers[0].first_name} {props.talk.speakers[0].last_name}
                </span>

                <span>
                  {props.talk.speakers[0].job_position},
                  {props.talk.speakers[0].company?.name
                  || props.talk.speakers[0].attendee_company}
                </span>
              </div>
            )}
          </Col>
        </Row>

        <Row style={{ margin: "10px 0px" }}>
          <p>
            {props.talk.description}
          </p>
        </Row>

        <Row justify="start" align="middle" gutter={10}>
          <Col>
            <Button
              className="pro-ant-btn"
              type="primary"
              icon={<PlayCircle size={20} style={{ marginRight: "10px" }} />}
              onClick={() => history.push(`/app/talk/${props.talk!.id}`)}
              disabled={!props.talk.isAvailable}
              style={{ pointerEvents: props.talk.isAvailable ? "unset" : "none" }}
            >
              Watch Talk
            </Button>
          </Col>

          <Col>
            <Button
              type="default"
              icon={
                myTalks.includes(props.talk?.id || "") ? (
                  <Trash size={20} style={{ marginRight: "10px" }} />
                ) : (
                  <PlusCircle size={20} style={{ marginRight: "10px" }} />
                )
              }
              disabled={isPending}
              loading={isPending}
              onClick={() => execute(!myTalks.includes(props.talk?.id || ""))}
              className="pro-ant-btn"
            >
              {myTalks.includes(props.talk?.id || "") ? "Remove From My List" : "Add To My List"}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  },
);

const TalkListImage = connect(
  (state: RootState, ownProps: { talk: TalkEntity, onClick: () => void }) => ({
    talk: ownProps.talk,
    myTalks: state.scheduleStore.myTalks,
    onClick: ownProps.onClick,
  }),
)(
  ({
    talk,
    myTalks,
    onClick,
  }: { talk: TalkEntity, myTalks: string[], onClick: () => void }) => {
    const [hovered, setHovered] = useState(false);
    const history = useHistory();

    const { apiService, dispatch } = useContext(DiContext);
    const scheduleSrv = scheduleService({ apiService, dispatch });

    const { isPending, execute, error } = useAsync((add: boolean) => {
      return add
        ? scheduleSrv.addTalkToMySchedule(talk.id)
        : scheduleSrv.removeTalkFromMySchedule(talk.id);
    });

    useOnError(error);

    return (
      <Col
        // onMouseEnter={() => talk.isAvailable && setHovered(true)}
        // onMouseLeave={() => talk.isAvailable && setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div
          style={{
            width: hovered ? 300 * 1.15 : "300px",
            height: hovered ? 170 * 1.15 : "170px",
            backgroundImage: `url(${talk.cover_url || ""})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            borderRadius: "12px",
            cursor: "pointer",
            // pointerEvents: talk.isAvailable ? "unset" : "none",
          }}
          role="none"
          onClick={onClick}
        />

        {(hovered && talk.isAvailable) && (
          <div
            style={{
              display: "flex",
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "50px",
              justifyContent: "space-between",
              backdropFilter: "blur(24px)",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              alignItems: "center",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            <Button
              type="ghost"
              icon={<PlayCircle size={18} style={{ marginRight: "5px" }} />}
              className="pro-ant-btn"
              style={{
                padding: "5px",
                color: "white",
                marginLeft: "10px",
              }}
              onClick={() => history.push(`/app/talk/${talk.id}`)}
            >
              {`Watch talk ${talk.length ? `(${talk.length} min)` : ""}`}
            </Button>

            <Button
              icon={
                myTalks.includes(talk.id) ? (
                  <Trash size={18} style={{ marginRight: "5px" }} />
                ) : (
                  <PlusCircle size={18} style={{ marginRight: "5px" }} />
                )
              }
              type="ghost"
              disabled={isPending}
              loading={isPending}
              onClick={() => execute(!myTalks.includes(talk.id))}
              className="pro-ant-btn"
              style={{
                padding: "5px",
                color: "white",
                marginRight: "10px",
              }}
            >
              {myTalks.includes(talk.id) ? "Remove From My List" : "Add To My List"}
            </Button>
          </div>
        )}
      </Col>
    );
  },
);

interface Props {
  trackId?: string | undefined;
  title: string;
  talks: TalkEntity[];
}

interface TalkListEntity {
  name: string;
  rank: number;
  talks: TalkEntity[];
}

const TalkList = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [talkSelected, setTalkSelected] = useState<undefined | TalkEntity>(undefined);

  const openTalk = (talk: TalkEntity) => {
    setTalkSelected(talk);
    setVisible(true);
  };

  const closeTalk = () => {
    setVisible(false);
    setTalkSelected(undefined);
  };

  return (
    <div style={{ margin: "60px 0px" }}>
      <Row justify="space-between" align="middle" className="title-row">
        <Col>
          <h1 style={{ marginBottom: 0 }}>
            {props.title}
          </h1>
        </Col>

        <Col>
          <NavLink
            style={{
              display: "flex",
              color: "#F75E2E",
              alignItems: "center",
              fontWeight: 700,
            }}
            to={props.title === "My List" ? "/app/mylist" : `/app/results?tracks=${props.trackId || ""}`}
          >
            See All
            <ChevronRight size={18} />
          </NavLink>
        </Col>
      </Row>

      <ScrollContainer
        className="ant-row ant-row-no-wrap ant-row-middle"
        style={{
          columnGap: 15,
          minHeight: 220,
        }}
        vertical={false}
        hideScrollbars={false}
      >
        {props.talks.sort((a, b) => (b.rank || 0) - (a.rank || 0)).map(
          (talk) => <TalkListImage talk={talk} onClick={() => openTalk(talk)} />,
        )}
      </ScrollContainer>

      {talkSelected && <TalkListModal visible={visible} talk={talkSelected} onClose={closeTalk} />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  myTalks: state.scheduleStore.myTalks,
});

export const Home = connect(mapStateToProps)(({ myTalks }: { myTalks: string[] }) => {
  const history = useHistory();

  const { apiService, dispatch } = useContext(DiContext);
  const talkSrv = talkService({ apiService, dispatch });

  const talks = useAsync(talkSrv.getAll);
  useOnError(talks.error);
  useOnMount(talks.execute);

  const tracks = (talks.value || []).reduce<{ [key: string]: TalkListEntity }>((acc, talk) => {
    const talkTrack = talk.tracks.reduce((a, track) => ({
      ...a,
      [track.id]: {
        name: track.name,
        rank: track.rank || 0,
        talks: [...(acc[track.id]?.talks || []), talk],
      },
    }), {});

    return {
      ...acc,
      ...talkTrack,
    };
  }, {});

  const trendingTalk = _.sample((talks.value || []).filter((talk) => talk.trendingTalk));

  const scheduleSrv = scheduleService({ apiService, dispatch });

  const { isPending, execute, error } = useAsync((add: boolean) => {
    return add
      ? scheduleSrv.addTalkToMySchedule(trendingTalk?.id || "")
      : scheduleSrv.removeTalkFromMySchedule(trendingTalk?.id || "");
  });

  useOnError(error);

  const getMyTalks = useAsync<TalkEntity[], unknown[]>(scheduleSrv.getMySchedule);
  useOnError(getMyTalks.error);
  useOnMount(getMyTalks.execute);

  const myListTalks = _.compact(myTalks.map((talkId) => (talks.value || []).find((x) => x.id === talkId)));

  if (talks.isPending || !talks.value) return null;

  return (
    <>
      {trendingTalk && (
        <div className="trending-talk-container">
          <Row gutter={60}>
            <Col md={24} lg={9} xl={11}>
              <img
                src={trendingTalk.cover_url}
                aria-hidden="true"
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "628px",
                  maxHeight: "340px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "12px",
                  cursor: "pointer",
                  marginBottom: "10px",
                  verticalAlign: "middle",
                }}
                onClick={() => history.push(`/app/talk/${trendingTalk.id}`)}
              />
            </Col>

            <Col md={24} lg={15} xl={13} style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
              <Tag
                style={{
                  background: "linear-gradient(180deg, #FDFD5E -9.52%, #F7C82E 100%)",
                  borderRadius: "10px",
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Trending
              </Tag>

              <div>
                <h1 style={{ wordBreak: "break-word", overflowWrap: "break-word", marginBottom: "10px" }}>
                  {trendingTalk.title}
                </h1>

                <span style={{ color: "#181922", fontWeight: 450 }}>
                  {trendingTalk.track.name} &#xb7; {trendingTalk?.length} min
                </span>

                <p style={{ marginTop: "10px" }}>
                  {trendingTalk.description}
                </p>
              </div>

              <Row justify="start" align="middle" gutter={10}>
                <Col>
                  <Button
                    className="pro-ant-btn"
                    type="primary"
                    icon={<PlayCircle size={20} style={{ marginRight: "10px" }} />}
                    onClick={() => history.push(`/app/talk/${trendingTalk.id}`)}
                  >
                    Watch Talk
                  </Button>
                </Col>

                <Col>
                  <Button
                    type="default"
                    icon={
                      myTalks.includes(trendingTalk.id) ? (
                        <Trash size={20} style={{ marginRight: "10px" }} />
                      ) : (
                        <PlusCircle size={20} style={{ marginRight: "10px" }} />
                      )
                    }
                    disabled={isPending}
                    loading={isPending}
                    onClick={() => execute(!myTalks.includes(trendingTalk.id))}
                    className="pro-ant-btn"
                  >
                    {myTalks.includes(trendingTalk.id) ? "Remove From My List" : "Add To My List"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}

      <div className="talks-container">
        {myListTalks.length > 0 && (
          <TalkList title="My List" talks={myListTalks} />
        )}

        {
          Object
            .keys(tracks)
            .sort((a, b) => tracks[b].rank - tracks[a].rank)
            .map((id) => (
              <TalkList trackId={id} title={tracks[id].name} talks={tracks[id].talks} />
            ))
        }
      </div>
    </>
  );
});
