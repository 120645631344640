import React from "react";
import { useHistory } from "react-router-dom";

import { Col, Row, BackTop, Card } from "antd";

import { connect } from "react-redux";
import { RootState } from "services";

import { PostList } from "components/Post/PostList";
import { PostModal } from "components/Post/PostModal";

import { CurrentlyStreaming } from "./currentlyStreaming";

interface OwnProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface LobbyPageProps {
  postId: string;
}

const mapStateToProps = (_state: RootState, ownProps: OwnProps): LobbyPageProps => {
  return {
    postId: ownProps.match.params.id,
  };
};

export const LobbyPage = connect(mapStateToProps)((props: LobbyPageProps) => {
  const history = useHistory();

  const redirectToLobby = () => {
    history.push("/app/updates");
  };

  return (
    <div className="wrapper">
      <div className="lobby-page">
        {props.postId && (
          <PostModal
            postId={props.postId}
            visible={true}
            onClose={redirectToLobby}
          />
        )}

        <CurrentlyStreaming />

        <div className="cnt-wrapper">
          <PostList />
        </div>
      </div>
      <BackTop />
    </div>
  );
});
